import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';

const MenuGroup = ({ name, displayName, active, iconName, items, sidebarIsOpen, loadedItem, handleClickGroupTitle, handleClickLink, calculatedTop }) => {
  const [menuHoverIsOpen, setMenuHoverIsOpen] = React.useState(false);

  return (
    <div
      className={`MenuGroup ${sidebarIsOpen ? '' : 'isClosed'}`}
      onMouseEnter={() => setMenuHoverIsOpen(true)}
      onMouseLeave={() => setMenuHoverIsOpen(false)}
    >
      <div className={`MenuGroupHeader ${active ? 'active' : ''}`} role="button" tabIndex={0} onClick={() => handleClickGroupTitle(name)}>
        <Icon className={`MenuGroupHeaderIcon ${menuHoverIsOpen ? ' hovered' : ''}`} icon={iconName} iconSize="25" />
        {sidebarIsOpen && <span className="MenuGroupTitle">{displayName}</span>}
      </div>
      {sidebarIsOpen &&
        active &&
        items.map((item) => {
          const itemPathName = `/${encodeURI(name)}${item === 'Home' ? '' : `/${encodeURI(item)}`}`;
          const isTheLoadedItem = loadedItem === itemPathName;
          return (
            <Link
              className={`MenuGroupItem ${isTheLoadedItem ? 'active' : ''}`}
              key={item}
              to={itemPathName}
              onClick={() => {
                setMenuHoverIsOpen(false);
                handleClickLink(name, itemPathName);
              }}
            >
              {item}
            </Link>
          );
        })}
      {!sidebarIsOpen && menuHoverIsOpen && (
        <div className="MenuGroupHover" style={{ top: calculatedTop }}>
          <p className="MenuGroupHoverTitle">{displayName}</p>
          <hr style={{ margin: '10px' }} />
          {items.map((item) => {
            const itemPathName = `/${encodeURI(name)}${item === 'Home' ? '' : `/${encodeURI(item)}`}`;
            const isTheLoadedItem = loadedItem === itemPathName;
            return (
              <Link
                className={`MenuGroupHoverItem ${isTheLoadedItem ? 'active' : ''}`}
                key={item}
                to={itemPathName}
                onClick={() => {
                  setMenuHoverIsOpen(false);
                  handleClickLink(name, itemPathName);
                }}
              >
                {item}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

MenuGroup.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  iconName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  loadedItem: PropTypes.string.isRequired,
  handleClickGroupTitle: PropTypes.func.isRequired,
  handleClickLink: PropTypes.func.isRequired,
  calculatedTop: PropTypes.string.isRequired,
};

export default MenuGroup;
