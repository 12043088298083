import React from 'react';
import PropTypes from 'prop-types';
import localforage from 'localforage';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import { useSelector, useDispatch } from 'react-redux';
import ganymedeActions from 'AppModules/Ganymede/Actions/ganymedeActions';
import ExplorerControls from './ExplorerControls';
import './ExplorerControls.css';

const ExplorerControlsContainer = (props) => {
  const dispatch = useDispatch();

  const { totalNbOfScreenshots, controls } = useSelector((store) => store.ganymedeReducer);
  const toggleGridView = controls?.toggleGridView || false;

  // When mounting, count the number of screenshots saved locally
  React.useEffect(() => {
    let isSubscribed = true;
    props.updateNbOfLocalScreenshotKeys(isSubscribed);
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleUpdateControl = (controlName, newValue) => {
    dispatch(ganymedeActions.updateExplorerControl(controlName, newValue));
  };

  const handleRemoveLocalScreenshots = async () => {
    props.setDeletingLocalCopyKeys(props.selectedScreenshotKeys);
    const removeItemPromises = [];
    for (const key of props.selectedScreenshotKeys) {
      removeItemPromises.push(localforage.removeItem(`ganymedeScreenshotData_${key}`));
    }
    await Promise.all(removeItemPromises);
    props.updateNbOfLocalScreenshotKeys();
    props.setDeletingLocalCopyKeys([]);
  };

  const removeAllLocalScreenshotsButton = async () => {
    props.setDeletingLocalCopyKeys(props.screenshots.map((el) => el.key)); // marking all displayed screenshots to make sure they all update if necessary
    const allKeys = await localforage.keys();
    const screenshotKeys = allKeys.filter((key) => key.includes('ganymedeScreenshotData_'));
    // remove keys
    const removeAllKeysPromises = [];
    for (const key of screenshotKeys) {
      removeAllKeysPromises.push(localforage.removeItem(key));
    }
    await Promise.all(removeAllKeysPromises);
    props.updateNbOfLocalScreenshotKeys();
    props.setDeletingLocalCopyKeys([]);
  };

  return (
    <ExplorerControls
      selectedScreenshotKeys={props.selectedScreenshotKeys}
      localNbOfScreenshots={props.localNbOfScreenshots}
      totalNbOfScreenshots={totalNbOfScreenshots}
      toggleGridView={toggleGridView}
      handleUpdateControl={handleUpdateControl}
      handleRemoveLocalScreenshots={handleRemoveLocalScreenshots}
      removeAllLocalScreenshotsButton={removeAllLocalScreenshotsButton}
      handleCompareScreenshots={props.handleCompareScreenshots}
      copyLinkToClipboard={props.copyLinkToClipboard}
    />
  );
};

ExplorerControlsContainer.propTypes = {
  screenshots: PropTypes.arrayOf(screenshotPropTypes).isRequired,
  selectedScreenshotKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDeletingLocalCopyKeys: PropTypes.func.isRequired,
  localNbOfScreenshots: PropTypes.number.isRequired,
  updateNbOfLocalScreenshotKeys: PropTypes.func.isRequired,
  handleCompareScreenshots: PropTypes.func.isRequired,
  copyLinkToClipboard: PropTypes.func.isRequired,
};

export default ExplorerControlsContainer;
