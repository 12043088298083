import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Checkbox, Spinner } from '@blueprintjs/core';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import ScreenshotRowContainer from './ScreenshotRow/ScreenshotRowContainer';
import './ExplorerList.css';

const ExplorerList = (props) => (
  <div className="ExplorerList">
    <table className="ExplorerListTable" cellSpacing="0" cellPadding="0">
      <thead>
        <tr>
          <th style={{ width: '5%', textAlign: 'center' }}>
            <Checkbox
              checked={props.screenshots.length === props.selectedScreenshotKeys.length}
              indeterminate={props.screenshots.length !== props.selectedScreenshotKeys.length && props.selectedScreenshotKeys.length > 0}
              onChange={props.handleSelectAllScreenshots}
            />
          </th>
          {props.tableHeaders.map((header) => (
            <th
              key={header.title}
              style={{ width: `${header.width * 0.95}%`, textAlign: header.alignHeader || ' center' }}
              onClick={() => props.handleSortItems(header.title)}
            >
              {props.sorting.headerTitle === header.title ? (
                <Icon style={{ margin: '0 4px 0 -20px' }} icon={props.sorting.ascending ? 'arrow-up' : 'arrow-down'} />
              ) : null}
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      {props.screenshotsListIsUpdating ? (
        <tbody className="loading">
          <tr>
            <td colSpan={props.tableHeaders.length + 1} style={{ height: `${35 * props.screenshots.length}px` }}>
              <Spinner size={25} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {props.screenshots.map((screenshot) => (
            <ScreenshotRowContainer
              key={screenshot.key}
              screenshot={screenshot}
              selected={props.selectedScreenshotKeys.includes(screenshot.key)}
              isDeleting={props.deletingLocalCopyKeys.includes(screenshot.key)}
              handleSelectScreenshot={props.handleSelectScreenshot}
              aScreenshotIsLoading={props.aScreenshotIsLoading}
              setAScreenshotIsLoading={props.setAScreenshotIsLoading}
              handleDisplayScreenshot={props.handleDisplayScreenshot}
              localNbOfScreenshots={props.localNbOfScreenshots}
              updateNbOfLocalScreenshotKeys={props.updateNbOfLocalScreenshotKeys}
              copyLinkToClipboard={props.copyLinkToClipboard}
            />
          ))}
        </tbody>
      )}
    </table>
  </div>
);

ExplorerList.propTypes = {
  tableHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      alignHeader: PropTypes.string,
      alignItems: PropTypes.string,
      title: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  sorting: PropTypes.shape({
    attribute: PropTypes.string.isRequired,
    ascending: PropTypes.bool.isRequired,
    headerTitle: PropTypes.string.isRequired,
  }).isRequired,
  handleSortItems: PropTypes.func.isRequired,
  screenshots: PropTypes.arrayOf(screenshotPropTypes).isRequired,
  selectedScreenshotKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  deletingLocalCopyKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectScreenshot: PropTypes.func.isRequired,
  handleSelectAllScreenshots: PropTypes.func.isRequired,
  aScreenshotIsLoading: PropTypes.bool.isRequired,
  setAScreenshotIsLoading: PropTypes.func.isRequired,
  handleDisplayScreenshot: PropTypes.func.isRequired,
  localNbOfScreenshots: PropTypes.number.isRequired,
  updateNbOfLocalScreenshotKeys: PropTypes.func.isRequired,
  screenshotsListIsUpdating: PropTypes.bool,
  copyLinkToClipboard: PropTypes.func.isRequired,
};

ExplorerList.defaultProps = {
  screenshotsListIsUpdating: false,
};

export default ExplorerList;
