/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Button, Intent, Dialog, FormGroup, InputGroup } from '@blueprintjs/core';
import ButtonWithWarning from 'Components/ButtonWithWarning/ButtonWithWarning';
import { pageTypes, requiredFields } from 'AppModules/Ganymede/utils/constants';
import MySelect from 'Components/MySelect/MySelect';
import { pagePropTypes } from '../../Definitions/ganymedePropTypes';
import './PageParamsDialog.css';


const PageParamsDialog = (props) => {
  // import MySelect from 'Components/MySelect/MySelect';

  const getHelperText = (fieldName, page) => ((requiredFields[page.type]?.includes(fieldName) && page[fieldName] === '') ? 'This field cannot be empty' : '');
  const getIntent = (fieldName, page) => ((requiredFields[page.type]?.includes(fieldName) && page[fieldName] === '') ? Intent.WARNING : null);

  const makeFormParameter = (parameterName, displayName, placeholder, disabled) => (
    <FormGroup
      className={props.page[parameterName] === '' ? 'invalid' : ''}
      label={displayName}
      labelFor={`${parameterName}-input`}
      labelInfo={requiredFields[props.page.type]?.includes(parameterName) ? '(required)' : ''}
      helperText={getHelperText(parameterName, props.page)}
      intent={getIntent(parameterName, props.page)}
    >
      <InputGroup
        id={`${parameterName}-input`}
        placeholder={placeholder}
        value={props.page[parameterName] || ''}
        onChange={(e) => props.handleChangeParam({ parameter: parameterName, value: e.target.value })}
        disabled={disabled}
        intent={getIntent(parameterName, props.page)}
      />
    </FormGroup>
  );

  return (
    <Dialog
      className="paramDialog"
      isOpen={props.isOpen}
      onClose={() => props.setParamDialogIsOpen(false)}
      title={props.mode === 'edit' ? `${props.page.name} - Edit Parameters` : 'Add New Page'}
      icon={props.mode === 'edit' ? 'cog' : 'add'}
      style={{ width: '800px' }}
    >
      <div className="parametersContent">
        <div className="top-row">
          <Switch
            checked={props.page.active}
            disabled={false}
            onChange={() => props.handleChangeParam({ parameter: 'active', value: !props.page.active })}
            label="Active"
          />
          <div className="pageTypeSelector">
            <p>Page Type:</p>
            <MySelect
              items={pageTypes}
              activeItem={props.page.type}
              handleItemSelect={(e) => { props.handleChangeParam({ parameter: 'type', value: e }); }}
              width={200}
            />
          </div>
        </div>
        {makeFormParameter('name', 'Name', 'e.g. SWO Tickets', props.mode === 'edit')}
        {makeFormParameter('URL', 'URL', 'https://seaworld.com/orlando/tickets/')}
        <div className="parametersContent_left">
          {makeFormParameter('parkGroup', 'Park Group', 'e.g. SeaWorld', false)}
          {makeFormParameter('page', 'Page', 'e.g. Tickets', false)}
          {makeFormParameter('localIP', 'Local IP address', 'e.g. 71.43.195.10', false)}
        </div>
        <div className="parametersContent_right">
          {makeFormParameter('park', 'Park', 'e.g. SWO', false)}
          {makeFormParameter('product', 'Product', 'e.g. SDT', props.page.type !== 'SDT Prices')}
          {makeFormParameter('nonLocalIP', 'Non-Local IP address', 'e.g. 71.43.195.10', false)}
        </div>
        <div className="buttons">
          <Button intent={Intent.WARNING} onClick={() => props.setParamDialogIsOpen(false)}>Cancel</Button>
          <ButtonWithWarning
            buttonText={props.mode === 'edit' ? 'Save' : 'Create'}
            buttonIntent={Intent.PRIMARY}
            buttonIcon={props.mode === 'edit' ? 'updated' : 'add'}
            warningBodyText={props.mode === 'edit'
              ? 'Are you sure you want to modify these parameters? This action cannot be undone'
              : 'Are you sure you want to create this page? Screenshots will be taken daily from now on'}
            warningIntent={Intent.WARNING}
            warningIcon="warning-sign"
            warningButtonText={props.mode === 'edit' ? 'Yes, Save' : 'Yes, Add'}
            onConfirm={props.confirmChangeParams}
            disabled={
              Object.keys(props.page).filter(
                (key) => requiredFields[props.page.type]?.includes(key),
              ).reduce(
                (acc, curr) => (acc || props.page[curr] === ''),
                false,
              )
            }
          />
        </div>
      </div>
    </Dialog>
  );
};

PageParamsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setParamDialogIsOpen: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['edit', 'add']).isRequired,
  page: pagePropTypes.isRequired,
  handleChangeParam: PropTypes.func.isRequired,
  confirmChangeParams: PropTypes.func.isRequired,
};

export default PageParamsDialog;
