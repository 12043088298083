/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Intent, ProgressBar, Button, Checkbox, Spinner } from '@blueprintjs/core';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';

const ScreenshotRow = (props) => (
  <tr className="ScreenshotRow" onClick={props.handleClickShowScreenshot}>
    <td
      onClick={(e) => {
        e.stopPropagation();
        props.handleSelectScreenshot(props.screenshot.key, !props.selected);
      }}
    >
      <Checkbox checked={props.selected} onClick={(e) => e.stopPropagation()} />
    </td>
    <td>
      <p>{props.screenshot.park}</p>
    </td>
    <td>
      <p>{props.screenshot.pageType}</p>
    </td>
    <td>
      <p>{props.screenshot.dateStr}</p>
    </td>
    <td>
      <p>{props.screenshot.timeStr}</p>
    </td>
    <td>
      <p>{props.screenshot.device}</p>
    </td>
    <td>
      <p>{props.screenshot.version}</p>
    </td>
    <td>
      <Button icon="link" minimal onClick={(e) => props.copyLinkToClipboard(e, [props.screenshot.key])} />
    </td>
    <td>
      {props.downloadProgress === null || props.downloadProgress === undefined ? (
        <Button icon="cloud-download" minimal onClick={props.handleClickDownloadScreenshot} />
      ) : props.downloadProgress === 1 ? (
        props.isDeleting ? (
          <Spinner size={Spinner.SIZE_SMALL} />
        ) : (
          <Icon icon="tick" intent={Intent.SUCCESS} />
        )
      ) : (
        <ProgressBar value={props.downloadProgress} intent={Intent.PRIMARY} animate={false} stripes={false} />
      )}
    </td>
  </tr>
);

ScreenshotRow.propTypes = {
  screenshot: screenshotPropTypes.isRequired,
  selected: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  handleSelectScreenshot: PropTypes.func.isRequired,
  handleClickShowScreenshot: PropTypes.func.isRequired,
  handleClickDownloadScreenshot: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
  copyLinkToClipboard: PropTypes.func.isRequired,
};

ScreenshotRow.defaultProps = {
  downloadProgress: null,
};

export default ScreenshotRow;
