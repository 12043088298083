import React from 'react';
import PropTypes from 'prop-types';
import DataContainer from '../Data/DataContainer';
import FiltersContainer from '../Filters/FiltersContainer';
import ChartsContainer from '../Charts/ChartsContainer';
import OptionsContainer from '../Options/OptionsContainer';

const TrafficConversion = (props) => (
  <div className="TrafficConversion">
    <div className="topRow">
      <OptionsContainer module="trafficConversion" subModule="timeSeries" />
      <DataContainer module="trafficConversion" dataInfo={props.dataInfo} refreshData={props.refreshData} downloadProgress={props.downloadProgress} />
    </div>

    <FiltersContainer
      module="trafficConversion"
      dataInfo={props.dataInfo}
      filterValues={props.filterValues}
      includeDates={false}
      // filterValues={{
      //   Park: props.filterValues.Park,
      //   Year: props.filterValues.Year,
      //   'Year-Month': props.filterValues['Year-Month'],
      //   Channel: props.filterValues.Channel,
      // }}
    />
    <ChartsContainer module="trafficConversion" chartData={props.chartData} />
  </div>
);

TrafficConversion.propTypes = {
  dataInfo: PropTypes.objectOf(
    PropTypes.shape({
      nbRows: PropTypes.number,
      fields: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  filterValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      year: PropTypes.string,
    }),
  ).isRequired,
  refreshData: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
};

TrafficConversion.defaultProps = {
  downloadProgress: null,
};

export default TrafficConversion;
