import localforage from 'localforage';
import { sortPerList } from '../utils/utils';
import ioAPI from '../API/ioAPI';
import ioActionTypes from './ioActionTypes';
import { dimSortings, emailReduced_headersRemap, emailReduced_valuesRemap, trafficConv_headersRemap, trafficConv_valuesRemap } from '../utils/constants';

const downloadFullData = async (token, onDownloadProgress) => {
  try {
    const { data } = await ioAPI.downloadData(token, 'trafficConversion/fullData.csv', trafficConv_headersRemap, trafficConv_valuesRemap, onDownloadProgress);
    // save data to localforage
    localforage.setItem('Io_trafficConversion_fullData', data);
    return { data, err: null };
  } catch (err) {
    // console.log(`Error: ${err}`);
    return { err: err.message };
  }
};

const downloadReducedEmailData = async (token, onDownloadProgress) => {
  try {
    const { data } = await ioAPI.downloadData(token, 'Email/reducedData.csv', emailReduced_headersRemap, emailReduced_valuesRemap, onDownloadProgress);
    // save data to localforage
    localforage.setItem('Io_Email_reducedData', data);
    return { emailReducedData: data, err: null };
  } catch (err) {
    // console.log(`Error: ${err}`);
    return { emailReducedErr: err.message };
  }
};

const downloadRunDetails = async (token) => {
  const { runDetails } = await ioAPI.downloadRunDetails(token);
  return runDetails;
};

const updateOption = ({ module, subModule, optionName, newValue }) => ({
  type: ioActionTypes.UPDATE_OPTION,
  module,
  subModule,
  optionName,
  newValue,
});

const updateFilter = ({ module, filterName, newItems }) => {
  const sortOrder = dimSortings[filterName];
  const newItemsSorted = sortOrder ? sortPerList(newItems, sortOrder) : newItems;

  return {
    type: ioActionTypes.UPDATE_FILTER,
    module,
    filterName,
    newItems: newItemsSorted,
  };
};

export default {
  downloadFullData,
  downloadReducedEmailData,
  downloadRunDetails,
  updateOption,
  updateFilter,
};
