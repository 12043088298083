import axios from 'axios';
import { Buffer } from 'buffer';
import { add } from 'date-fns';
import { APIbaseURL } from '../utils/awsConfig';
import { getImgDimensions } from '../utils/util';

const axiosJson = axios.create({ baseURL: APIbaseURL, responseType: 'json' });
const axiosArrayBuffer = axios.create({ baseURL: APIbaseURL, responseType: 'arraybuffer' });

const toQueryString = (params) =>
  Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

const getAxiosErrorMessage = (err) => {
  if (err.response) {
    const { status, statusText } = err.response;
    return `${status} - ${statusText}`;
  }
  if (err.request) {
    const { status, statusText } = err.request;
    return `${status} - ${statusText}`;
  }
  return err.message;
};

const getKeys = async (pageName, token) => {
  if (!pageName) throw new Error('Error getting images: no pageName provided');

  const parameters = { pageName };
  const URL = `${APIbaseURL}/ganymedeAPI/getKeys?${toQueryString(parameters)}`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const raw_response = await fetch(URL, options);
    const res = await raw_response.json();
    return res;
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

const getImage = async (key, token, onDownloadProgress) => {
  if (!key) throw new Error('Error getting image: no key provided');
  axiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;

  // get S3 pre-signed URL
  const raw_response = await axiosJson.get(`/ganymedeAPI/getPresignedS3URL?${toQueryString({ key })}`);
  const preSignedURL = raw_response.data;

  // Download the image
  try {
    const img_raw_response = await axiosArrayBuffer.get(preSignedURL, { onDownloadProgress });
    const src = `data:image/png;base64, ${Buffer.from(img_raw_response.data).toString('base64')}`;
    const { width, height } = await getImgDimensions(src);
    return { key, width, height, src };
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

const getPagesList = async (token, pageName) => {
  axiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const raw_response = await axiosJson.get(`/ganymedeAPI/getPagesList?${toQueryString({ pageName: pageName || '' })}`);
    return raw_response.data;
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

const updatePageParameters = async (pageName, parameterValues, token) => {
  const parameters = { pageName, parameterValues };

  const URL = `${APIbaseURL}/ganymedeAPI/updatePageParameters`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(parameters),
  };

  try {
    const raw_response = await fetch(URL, options);
    const res = await raw_response.json();
    return res;
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

const getScreenshots = async (token, filtersSelection) => {
  const parameters = { filtersSelection };

  const URL = `${APIbaseURL}/ganymedeAPI/getScreenshots`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(parameters),
  };

  const raw_response = await fetch(URL, options);
  const res = await raw_response.json();
  return res;
};

const getPricesData = async (token, priceMode, filtersSelection) => {
  const parameters = {
    priceMode,
    filtersSelection: {
      ...filtersSelection,
      eventDate: add(filtersSelection.eventDate, { hours: 12 }),
      additionalEventDates: filtersSelection.additionalEventDates.map((el) => ({
        type: el.type,
        value: add(el.value, { hours: 12 }),
      })),
      snapshotDate: add(filtersSelection.snapshotDate, { hours: 12 }),
      additionalSnapshotDates: filtersSelection.additionalSnapshotDates.map((el) => ({
        type: el.type,
        value: add(el.value, { hours: 12 }),
      })),
    },
  };

  const URL = `${APIbaseURL}/ganymedeAPI/getPricesData`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(parameters),
  };

  const raw_response = await fetch(URL, options);
  const res = await raw_response.json();

  return res;
};

export default {
  getImage,
  getKeys,
  getPagesList,
  updatePageParameters,
  getScreenshots,
  getPricesData,
};
