/* eslint-disable import/prefer-default-export */

const calculateMetric = (el, metric) => {
  if (metric === 'Sales units') return el['Sales Qty'];
  if (metric === 'Sales per cap') return el['Sales Revenue'] / el['Sales Qty'];
  if (metric === 'Sales Revenue') return el['Sales Revenue'];
  if (metric === 'Attendance') return el.Attendance;
  if (metric === 'Admission per cap') return el['Admission Revenue'] / el.Attendance;
  if (metric === 'Admission Revenue') return el['Admission Revenue'];
  if (metric === 'Weather Code') return el.sum_weights === 0 ? 0 : el.sum_weigthedWeatherCode / el.sum_weights;
  if (metric === 'Real Weather - Max Temperature') return el.tempmax;
  if (metric === 'Real Weather - Max Feels Like Temperature') return el.feelslikemax;
  if (metric === 'Real Weather - Precipitation') return el.precip;
  if (metric === 'Real Weather - Cloud Cover') return el.cloudcover;
  return el[metric];
};

const convertWeatherData = (data, units) => (units.temp === 'C' ? data : data.map((yearData) => ({ ...yearData,
  data: yearData.data.map((el) => ({
    ...el,
    temp: Math.round(10 * (el.temp * 1.8 + 32)) / 10,
    tempmin: Math.round(10 * (el.tempmin * 1.8 + 32)) / 10,
    tempmax: Math.round(10 * (el.tempmax * 1.8 + 32)) / 10,
    feelslike: Math.round(10 * (el.feelslike * 1.8 + 32)) / 10,
    feelslikemin: Math.round(10 * (el.feelslikemin * 1.8 + 32)) / 10,
    feelslikemax: Math.round(10 * (el.feelslikemax * 1.8 + 32)) / 10,
  })) })));


const sortPerList = (arr, sortOrder) => {
  if (!sortOrder) return arr.sort();

  const itemsInSortOrder = sortOrder.filter((el) => arr.includes(el));
  const itemsNotInSortOrder = arr.filter((el) => !itemsInSortOrder.includes(el));
  return [...itemsInSortOrder, ...itemsNotInSortOrder];
};

export {
  calculateMetric,
  convertWeatherData,
  sortPerList,
};
