import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { calculateMetric } from 'AppModules/Europa/utils/utils';
import { metricFormatMatrix, metricFormatMatrix_exact } from 'AppModules/Europa/utils/constants';
import DataTable from 'AppModules/Europa/Components/DataTable/DataTable';
import HeatmapChart from './HeatmapChart';

const HeatmapChartContainer = (props) => {
  const { isDarkMode } = useSelector((store) => store.userReducer.userSettings);
  const { options } = useSelector((store) => store.europaReducer[props.module]);

  const data = props.data.map((yearData) => {
    // console.log({ yearData });
    const xData = [];
    let yData = [];
    const yText = [];
    const deltaPct = [];
    const text = [];
    for (const [j, el] of yearData.data.entries()) {
      xData.push(el.Date);
      const dataValue = calculateMetric(el, props.metric);
      yData.push(dataValue);
      yText.push(numeral(dataValue).format(metricFormatMatrix_exact[props.metric])); // for Tables
      // let currentDeltaPct = [];
      // if (yearData.year === 'CY') {
      //   currentDeltaPct = props.data.filter((arr) => arr.year !== 'CY').map((priorYearData) => ({
      //     year: priorYearData.year,
      //     value: dataValue / calculateMetric(priorYearData.data[j] || {}, props.metric),
      //   }));
      //   deltaPct.push(currentDeltaPct);
      // }
      // text.push([
      //   `<b>${el.Date}</b>`,
      //   numeral(dataValue).format(metricFormatMatrix[props.metric]),
      //   ...currentDeltaPct.map((deltaEl) => `${Math.round(100 * deltaEl.value)}% of ${deltaEl.year}`),
      //   `Weather Code: ${numeral(calculateMetric(el, 'Weather Code')).format(metricFormatMatrix_exact['Weather Code'])}`,
      // ].join('<br>'));
    }

    let zData = [[0]];
    const zData_CY = yData.map((el) => Math.round(el * 10) / 10);
    const zData_priorYears = props.data
      .filter((arr) => arr.year !== 'CY')
      .map((priorYearData) => priorYearData.data.map((el) => calculateMetric(el, props.metric)));
    zData = [zData_CY, ...zData_priorYears.map((priorYearData) => priorYearData.map((el) => Math.round(el * 10) / 10))].reverse();

    // const zText = props.data,map(yearData => yearData.data.map(dataPoint => `${dataPoint.date} - ${dataPoint.}`))

    const yData_CY = 'CY';
    const yData_priorYears = props.data.filter((arr) => arr.year !== 'CY').map((priorYearData) => priorYearData.year);
    yData = [yData_CY, ...yData_priorYears].reverse();
    // chartMode = '';
    // chartType = 'heatmap';
    const colorscale = [
      [0.0, 'rgba(19, 124, 189, 0)'],
      [0.33, 'rgb(41, 166, 52)'],
      [0.66, 'rgb(242, 157, 73)'],
      [1.0, 'rgb(209, 57, 19)'],
    ];
    const chartHeight = 70 + 15 * props.data.length;

    const output = {
      x: xData,
      y: yData,
      z: zData,
      yText,
      text,
      deltaPct,
      name: yearData.year,
      color: yearData.color,
      colorscale,
      chartHeight,
    };

    // if (deltaPct.length > 0) output.deltaPct = deltaPct;

    return output;
  });

  const tableHeaders = ['Date', ...data.map((el) => el.name).flat()];
  const tableData = data[0].x.map((date, i) => [date, ...data.map((el) => numeral(el.yText[i]).format(metricFormatMatrix[props.metric]))]);

  const copyData = () => props.copyDataToClipboard(tableHeaders, tableData);

  return options.toggleTable ? (
    <DataTable title={props.metric} tableHeaders={tableHeaders} tableData={tableData} isDarkMode={isDarkMode} />
  ) : (
    <HeatmapChart metric={props.metric} data={data} copyData={copyData} isDarkMode={isDarkMode} />
  );
};

HeatmapChartContainer.propTypes = {
  module: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      year: PropTypes.string,
    }),
  ).isRequired,
  copyDataToClipboard: PropTypes.func.isRequired,
};

export default HeatmapChartContainer;
