import React from 'react';
import PropTypes from 'prop-types';
import { Intent, Toaster } from '@blueprintjs/core';
import './Charts.css';
import LinesChartContainer from './LinesChart/LinesChartContainer';

const copyDataToClipboardToaster = Toaster.create();

// const metrics = [['Traffic (sessions)', 'Conversion Rate', 'Transactions', 'Revenues']];

const ChartsContainer = (props) => {
  const copyDataToClipboard = async (tableHeaders, tableData) => {
    const text = [tableHeaders, ...tableData].map((row) => row.join('\t')).join('\n');
    try {
      await navigator.clipboard.writeText(text);
      copyDataToClipboardToaster.show({ message: 'Data succesfully copied to clipboard', intent: Intent.SUCCESS, icon: 'tick' });
    } catch (e) {
      copyDataToClipboardToaster.show({ message: `Error copying data to clipboard: ${e.message}`, intent: Intent.DANGER, icon: 'warning-sign' });
    }
    // return res;
  };

  return (
    props.chartData[0]?.data.length > 0 && (
      <div className="chartsContainer">
        <div className="left-column">
          <LinesChartContainer
            module={props.module}
            title="Traffic (sessions)"
            metrics={{
              sessions: 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          <LinesChartContainer
            module={props.module}
            title="Transactions"
            metrics={{
              transactions: 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          <LinesChartContainer
            module={props.module}
            title="Traffic (users)"
            metrics={{
              users: 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
        </div>
        <div className="right-column">
          <LinesChartContainer
            module={props.module}
            title="Conversion Rate"
            metrics={{
              'Conversion Rate': 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          <LinesChartContainer
            module={props.module}
            title="Total Revenues"
            metrics={{
              revenues: 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
        </div>
      </div>
    )
  );
};

ChartsContainer.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      year: PropTypes.string,
    }),
  ).isRequired,
  module: PropTypes.string.isRequired,
};

export default ChartsContainer;
