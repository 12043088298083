/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button, H6 } from '@blueprintjs/core';

const DataUpload = (props) => {
  const style = {
    width: '100%',
    height: '50px',
  };

  return (
    <div style={{ width: '100%' }}>
      <H6>{props.title}</H6>
      <Dropzone className="fileSelectorSection" onDrop={(files) => props.handleFileDrop(files, props.title)}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div className={isDragActive ? 'fileSelectorSection active' : 'fileSelectorSection'} {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Button icon="upload" />
            {/* <p style={{ marginTop: '10px' }}>{isDragActive ? 'OK you can drop them!' : 'Drag file onto this area'}</p> */}
          </div>
        )}
      </Dropzone>
    </div>
  );
};

DataUpload.propTypes = {
  handleFileDrop: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default DataUpload;
