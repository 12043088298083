import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import Data from './Data';
import europaActions from '../Actions/europaActions';
import { yearsToDownload } from '../utils/constants';

const DataContainer = (props) => {
  const dispatch = useDispatch();

  const token = useSelector((store) => store.authReducer.token || '');
  const [dataUpdateStatus, setDataUpdateStatus] = React.useState({ upToDate: true });
  const { dataParameters } = useSelector((store) => store.europaReducer[props.module]);
  const [dataParametersDialogIsOpen, setDataParametersDialogIsOpen] = React.useState(false);
  const [refreshButtonText, setRefreshButtonText] = React.useState(`Refresh ${yearsToDownload.length} files`);

  const runDetails = useQuery(['runDetails'], () => europaActions.downloadRunDetails(token));
  React.useEffect(() => {
    if (runDetails.data && props.dataInfo.Europa_salesAttendance_fullData) {
      const lastDateRemote = runDetails.data.lastDate;
      const lastDateLocal = props.dataInfo.Europa_salesAttendance_fullData.lastDate;
      setDataUpdateStatus({
        lastDateRemote,
        lastDateLocal,
        upToDate: new Date(lastDateRemote) <= new Date(lastDateLocal),
      });
    }
  }, [runDetails.data, props.dataInfo]);

  // update Refresh button text when dataParameters.filesToDownload changes
  React.useEffect(() => {
    const nbFilesToDownload = Object.values(dataParameters.filesToDownload).filter((el) => !!el.value).length;
    const newRefreshButtonText = `Refresh ${nbFilesToDownload} file${nbFilesToDownload > 1 ? 's' : ''}`;
    setRefreshButtonText(newRefreshButtonText);
  }, [dataParameters.filesToDownload]);

  const toggleDataParametersDialog = () => {
    setDataParametersDialogIsOpen(!dataParametersDialogIsOpen);
  };

  const toggleFileToDownload = (fileName) => {
    dispatch(europaActions.toggleFileToDownload(fileName));
  };

  return (
    <Data
      dataInfo={props.dataInfo}
      runDetails={runDetails}
      dataUpdateStatus={dataUpdateStatus}
      refreshData={props.refreshData}
      downloadProgress={props.downloadProgress}
      refreshButtonText={refreshButtonText}
      dataParametersDialogIsOpen={dataParametersDialogIsOpen}
      toggleDataParametersDialog={toggleDataParametersDialog}
      toggleFileToDownload={toggleFileToDownload}
      dataParameters={dataParameters}
    />
  );
};

DataContainer.propTypes = {
  module: PropTypes.string.isRequired,
  dataInfo: PropTypes.objectOf(
    PropTypes.shape({
      nbRows: PropTypes.number,
      fields: PropTypes.arrayOf(PropTypes.string),
      lastDate: PropTypes.string,
    }),
  ).isRequired,
  refreshData: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
};

DataContainer.defaultProps = {
  downloadProgress: null,
};

export default DataContainer;
