// API Gateway
const stage = process.env.REACT_APP_STAGE || 'dev';
const API_URLs = {
  production: 'https://ue36v9sj1e.execute-api.eu-west-1.amazonaws.com/dev',
  dev: 'https://ue36v9sj1e.execute-api.eu-west-1.amazonaws.com/dev',
};

const weatherAPI_URLs = {
  production: 'https://ldwrhetyn8.execute-api.eu-west-1.amazonaws.com/dev',
  dev: 'https://ldwrhetyn8.execute-api.eu-west-1.amazonaws.com/dev',
};

const APIbaseURL = API_URLs[stage];
const weatherAPIbaseURL = weatherAPI_URLs[stage];

// eslint-disable-next-line import/prefer-default-export
export { APIbaseURL, weatherAPIbaseURL };
