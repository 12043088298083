import React from 'react';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import TrafficConversionContainer from './TrafficConversion/TrafficConversionContainer';

const IoContainer = () => (
  <div className="io">
    <Switch>
      <Route exact path="/Io/Traffic & Conversion" component={TrafficConversionContainer} />
    </Switch>
  </div>
);

export default IoContainer;
