import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { priorYears, timeGroupings } from '../utils/constants';
import Options from './Options';
import europaActions from '../Actions/europaActions';
import { sortPerList } from '../utils/utils';

const OptionsContainer = (props) => {
  const dispatch = useDispatch();

  const { options } = useSelector((store) => store.europaReducer[props.module]);

  const handleUpdateOptions = ({ optionName, newValue }) => {
    // for the priorYears, sort them first
    const PY_sortOrder = Object.keys(priorYears);
    const newValueSorted = typeof newValue === 'string' || typeof newValue === 'boolean' ? newValue : sortPerList(newValue, PY_sortOrder);

    // const newValueSorted = newValue;
    dispatch(europaActions.updateOption({ module: props.module, optionName, newValue: newValueSorted }));
  };

  return <Options priorYears={priorYears} timeGroupings={timeGroupings} options={options} handleUpdateOptions={handleUpdateOptions} />;
};

OptionsContainer.propTypes = {
  dataInfo: PropTypes.objectOf(
    PropTypes.shape({
      nbRows: PropTypes.number,
      fields: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  module: PropTypes.string.isRequired,
};

export default OptionsContainer;
