/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import MyMultiSelect from 'Components/MyMultiSelect/MyMultiSelect';
// import { filterPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import './ExplorerFilters.css';
import { DateRangeInput } from '@blueprintjs/datetime';
import { Button, HTMLSelect, Intent } from '@blueprintjs/core';
import definitions from '../../Definitions/definitions';

const ExplorerFilters = (props) => (
  <div className="ExplorerFilters">
    <div className="dateFilters">
      <h4>Date Ranges</h4>
      <section className="primaryDateFilter">
        <DateRangeInput
          formatDate={(date) => format(date, 'MM/dd/yy')}
          onChange={([from, to]) => props.handleUpdateFilter('snapshotDate', { from, to })}
          value={[props.filtersSelection.snapshotDate?.from, props.filtersSelection.snapshotDate?.to]}
          parseDate={(str) => new Date(str)}
          placeholder="MM/DD/YY"
          allowSingleDayRange
          // closeOnSelection={false}
          startInputProps={{ fill: true }}
          endInputProps={{ fill: true }}
        />
        <Button icon="add" intent={Intent.PRIMARY} onClick={props.handleAddDateFilter} minimal disabled={props.filtersSelection.additionalSnapshotDates?.length > 9} />
      </section>
      {props.filtersSelection.additionalSnapshotDates?.map((additionalDateFilter, additionalDateFilterID) => (
        // eslint-disable-next-line react/no-array-index-key
        <section className="additionalDateFilter" key={additionalDateFilterID}>
          <DateRangeInput
            formatDate={(date) => format(date, 'MM/dd/yy')}
            onChange={([from, to]) => props.handleUpdateFilter(`additionalSnapshotDate${additionalDateFilterID}`, { from, to })}
            value={[additionalDateFilter.from, additionalDateFilter.to]}
            parseDate={(str) => new Date(str)}
            placeholder="MM/DD/YY"
            allowSingleDayRange
            closeOnSelection={false}
            startInputProps={{ fill: true }}
            endInputProps={{ fill: true }}
            disabled={additionalDateFilter.type !== 'Custom'}
          />
          <HTMLSelect
            options={definitions.dateRangeTypes.map((el) => el.name)}
            value={additionalDateFilter.type}
            onChange={(e) => props.handleChangeDateFilterType(additionalDateFilterID, e.target.value)}
            minimal
          />
          <Button icon="trash" intent={Intent.DANGER} onClick={() => props.handleRemoveDateFilter(additionalDateFilterID)} minimal />
        </section>
      ))}
    </div>
    <section key="Park Group">
      <h4>Park Group</h4>
      <MyMultiSelect
        items={props.filters['Park Group']?.values}
        selectedItems={props.filtersSelection['Park Group']}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Park Group', newItems)}
        ctrlSelectMode
      />
    </section>
    <section key="Park">
      <h4>Park</h4>
      <MyMultiSelect
        items={props.filters.Park?.values}
        selectedItems={props.filtersSelection.Park}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Park', newItems)}
        ctrlSelectMode
      />
    </section>
    <section key="Page">
      <h4>Page</h4>
      <MyMultiSelect
        items={props.filters.Page?.values}
        selectedItems={props.filtersSelection.Page}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Page', newItems)}
        ctrlSelectMode
      />
    </section>
    <section key="Version">
      <h4>Version</h4>
      <MyMultiSelect
        items={props.filters.Version?.values}
        selectedItems={props.filtersSelection.Version}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Version', newItems)}
        ctrlSelectMode
      />
    </section>
    <section key="Device">
      <h4>Device</h4>
      <MyMultiSelect
        items={props.filters.Device?.values}
        selectedItems={props.filtersSelection.Device}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Device', newItems)}
        ctrlSelectMode
      />
    </section>
    {/* ))} */}
  </div>
);

ExplorerFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  filtersSelection: PropTypes.object.isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
  handleAddDateFilter: PropTypes.func.isRequired,
  handleRemoveDateFilter: PropTypes.func.isRequired,
  handleChangeDateFilterType: PropTypes.func.isRequired,
};

export default ExplorerFilters;
