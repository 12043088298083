import React from 'react';
import PropTypes from 'prop-types';
import { H6, Intent, ProgressBar, Toaster } from '@blueprintjs/core';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import ganymedeActions from 'AppModules/Ganymede/Actions/ganymedeActions';
import { useSelector } from 'react-redux';
import localforage from 'localforage';

const downloadErrorToaster = Toaster.create();

const ScreenshotInOverlay = ({ img, updateNbOfLocalScreenshotKeys }) => {
  const { token } = useSelector((store) => store.authReducer);

  const [downloadProgress, setDownloadProgress] = React.useState(0);
  const [screenshotImgData, setScreenshotImgData] = React.useState(img);

  const onDownloadProgress = (progressEvent) => {
    setDownloadProgress(progressEvent.loaded / progressEvent.total);
  };

  // When mounting, load imgData from localforage if it exists; otherwise download it
  React.useEffect(() => {
    let isSubscribed = true; // needed because the async function could resolve after the component has unmounted

    if (!img.src) {
      const downloadScreenshot = async () => {
        // setDownloadProgress(0); // Set progress to show that download has started (instead of waiting for the first progress event)
        const { imgData, err } = await ganymedeActions.downloadScreenshot(img.key, onDownloadProgress, token);
        if (!err) {
          setScreenshotImgData({
            screenshot: img.screenshot,
            ...imgData,
          });
          updateNbOfLocalScreenshotKeys();
        } else {
          downloadErrorToaster.show({ message: err, intent: Intent.DANGER, icon: 'warning-sign' });
          setDownloadProgress(null);
        }
        return { imgData, err };
      };

      const getImgData = async () => {
        const imgData = await localforage.getItem(`ganymedeScreenshotData_${img.key}`);
        if (isSubscribed) {
          if (imgData) {
            setScreenshotImgData({
              screenshot: img.screenshot,
              ...imgData,
            });
          } else {
            downloadScreenshot();
          }
        }
      };
      getImgData();
    }

    // Cleanup
    return () => {
      isSubscribed = false;
    };
  }, []);

  return screenshotImgData.src ? (
    <div key={screenshotImgData.key} className="screenshotDialog">
      <H6>{`
        ${screenshotImgData.screenshot.park} | 
        ${screenshotImgData.screenshot.pageType} | 
        ${screenshotImgData.screenshot.dateStr} | 
        ${screenshotImgData.screenshot.version}
      `}</H6>
      <img src={screenshotImgData.src} alt="snapshot" />
    </div>
  ) : (
    <div key={screenshotImgData.key} className="screenshotDialog">
      <H6>{`
        ${screenshotImgData.screenshot.park} | 
        ${screenshotImgData.screenshot.pageType} | 
        ${screenshotImgData.screenshot.dateStr} | 
        ${screenshotImgData.screenshot.version}
    `}</H6>
      <ProgressBar value={downloadProgress} intent={Intent.PRIMARY} animate={false} stripes={false} />
    </div>
  );
};

ScreenshotInOverlay.propTypes = {
  img: PropTypes.shape({
    key: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string,
    screenshot: screenshotPropTypes,
  }).isRequired,
  updateNbOfLocalScreenshotKeys: PropTypes.func.isRequired,
};

export default ScreenshotInOverlay;
