/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Auth0Provider from './Auth/react-auth0-spa';
import store from './utils/store';

import config from './Auth/auth_config.json';
// import registerServiceWorker from './registerServiceWorker';

import './index.css';
import App from './App';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  window.history.replaceState({}, document.title, appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const stage = process.env.REACT_APP_STAGE || 'dev';
const queryClient = new QueryClient();

ReactDOM.render(
  <Auth0Provider
    domain={config[stage].domain}
    client_id={config[stage].clientId}
    redirect_uri={window.location.origin}
    audience={config[stage].audience}
    onRedirectCallback={onRedirectCallback}
  >
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App stage={stage} />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root'),
);
// registerServiceWorker();
