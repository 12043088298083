import axios from 'axios';
import { APIbaseURL } from '../utils/awsUtils';

const axiosJson = axios.create({ baseURL: APIbaseURL, responseType: 'json' });
const axiosText = axios.create({ baseURL: APIbaseURL, responseType: 'text' });

const toQueryString = (params) =>
  Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

const getAxiosErrorMessage = (err) => {
  if (err.response) {
    const { status, statusText } = err.response;
    return `${status} - ${statusText}`;
  }
  if (err.request) {
    const { status, statusText } = err.request;
    return `${status} - ${statusText}`;
  }
  return err.message;
};

const downloadData = async (token, key, headersRemap, valuesRemap, onDownloadProgress) => {
  // const key = options.type === 'full' ? 'trafficConversion/fullData.csv' : '';
  axiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;

  // get S3 pre-signed URL
  const rawPresignedURL = await axiosJson.get(`/getS3FileURL?${toQueryString({ filePath: key })}`);
  const preSignedURL = rawPresignedURL.data;
  // console.log(preSignedURL);

  // Download the file
  try {
    const rawData = await axiosText.get(preSignedURL, { onDownloadProgress });
    const data = rawData.data.split(/\r?\n/);
    const data_array = data.map((el) => el.split(','));

    // convert to array of objects
    const headersRaw = data_array[0];

    // Remap headers
    const headers = headersRaw.map((el) => headersRemap[el] || el);
    // console.log(headers);

    // Remap values
    const dataValues = data_array.slice(1);
    const objectsData = dataValues.map((el) => {
      const currentRow = {};
      for (let i = 0; i < headers.length; i += 1) {
        if (headers[i] === 'Date') {
          // convert the date from yyyymmdd to yyyy-mm-dd
          currentRow[headers[i]] = `${el[i].slice(0, 4)}-${el[i].slice(4, 6)}-${el[i].slice(6, 8)}`;
        } else {
          currentRow[headers[i]] = ['0', '0.0', '0.00', '0.000'].includes(el[i]) ? 0 : +el[i] || el[i]; // converting numbers
        }
      }

      for (const dimToRemap of Object.keys(valuesRemap)) {
        // eslint-disable-next-line no-unreachable-loop
        let found = false;
        for (const [oldValue, newValue] of Object.entries(valuesRemap[dimToRemap].remaps)) {
          if (currentRow[dimToRemap] === oldValue) {
            currentRow[dimToRemap] = newValue;
            found = true;
            break;
          }
        }
        if (!found) currentRow[dimToRemap] = valuesRemap[dimToRemap].catchall;
      }
      return currentRow;
    });
    // console.log(objectsData.slice(0, 2));

    return { data: objectsData, err: null };
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

const downloadRunDetails = async (token) => {
  axiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;
  // get S3 pre-signed URL
  const rawPresignedURL = await axiosJson.get(`/getS3FileURL?${toQueryString({ filePath: 'trafficConversion/runDetails.csv' })}`);
  const preSignedURL = rawPresignedURL.data;

  // Download the file
  try {
    const rawData = await axiosText.get(preSignedURL);
    const data = rawData.data.split(/\r?\n/);
    const headers = data[0].split(',');
    const values = data[1].split(',');
    const runDetails = {};
    for (const [index, header] of headers.entries()) {
      runDetails[header] = values[index];
    }
    return { runDetails, err: null };
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

export default {
  downloadData,
  downloadRunDetails,
};
