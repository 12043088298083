import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import Filters from './Filters';
import europaActions from '../Actions/europaActions';

const FiltersContainer = (props) => {
  const dispatch = useDispatch();

  const { filtersSelection } = useSelector((store) => store.europaReducer[props.module]);

  const handleUpdateFilter = (filterName, newItems) => {
    dispatch(europaActions.updateFilter({ module: props.module, filterName, newItems }));
  };

  if (Object.keys(props.dataInfo).length === 0 || props.dataInfo[module]?.nbRows === 0) return null;

  return Object.keys(props.filterValues).length > 0 ? (
    <Filters filterValues={props.filterValues} filtersSelection={filtersSelection} handleUpdateFilter={handleUpdateFilter} />
  ) : (
    <div className="filtersLoading">
      <Spinner size={20} />
      <p>Generating filter values...</p>
    </div>
  );
};

FiltersContainer.propTypes = {
  dataInfo: PropTypes.objectOf(
    PropTypes.shape({
      nbRows: PropTypes.number,
      fields: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  filterValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  module: PropTypes.string.isRequired,
};

export default FiltersContainer;
