import { format, getISODay, lastDayOfISOWeek, lastDayOfMonth, lastDayOfYear } from 'date-fns';
import localforage from 'localforage';
import europaAPI from '../API/europaAPI';
import { dimSortings, priorYears, weatherCodeWeights } from '../utils/constants';
import { sortPerList } from '../utils/utils';
import europaActionTypes from './europaActionTypes';

const getUS_Timestamp = (date, applyOffset, park) => {
  const parkOffset = {
    SWF: 6, // one more hour than the normal offset for the case when
    APO: 5, // US has switched summer/winter time at a different time than Europe
    DCO: 5,
    BGT: 5,
    AIT: 5,
    SWC: 8,
    SPC: 8,
    APC: 8,
    SWT: 6,
    APT: 6,
    BGW: 5,
    WCW: 5,
    SPL: 5,
  };
  return date.getTime() / 1000 - (date.getTimezoneOffset() - 60 * (applyOffset ? parkOffset[park] : 0)) * 60;
};

const reduceDateRanges = (years, yearMonths, park) => {
  // if no yearMonths nor years return []
  if (years.length === 0 && yearMonths.length === 0) return [];
  // if no yearMonths, return the year epochs
  if (!yearMonths || yearMonths.length === 0) {
    return years.map((year) => {
      const date1 = new Date(year, 0, 1);
      const date2 = lastDayOfYear(date1);
      return [getUS_Timestamp(date1, false, park), getUS_Timestamp(date2, true, park)];
    });
  }
  const resRanges = [];
  for (const yearMonth of yearMonths) {
    const theYear = yearMonth.slice(0, 4);
    if (years.length === 0 || years.includes(theYear)) {
      // if no year is selected go for it
      const year = yearMonth.slice(0, 4);
      const month = yearMonth.slice(7, 9);
      const date1 = new Date(year, month - 1, 1);
      const date2 = lastDayOfMonth(date1);
      const range = [getUS_Timestamp(date1, false, park), getUS_Timestamp(date2, true, park)];
      resRanges.push(range);
    }
  }
  return resRanges;
};

const downloadFullData = async (token, onDownloadProgress) => {
  try {
    const { data } = await europaAPI.downloadData(token, { type: 'full' }, onDownloadProgress);
    // save data to localforage
    localforage.setItem('Europa_salesAttendance_fullData', data);
    return { data, err: null };
  } catch (err) {
    // console.log(`Error: ${err}`);
    return { err: err.message };
  }
};

const downloadRunDetails = async (token) => {
  const { runDetails } = await europaAPI.downloadRunDetails(token);
  return runDetails;
};

const getWeatherData = async (token, filtersSelection, options) => {
  // 1. .Convert year and year-month selection into start and end date
  const CY_Ranges = reduceDateRanges(filtersSelection.Year, filtersSelection['Year-Month'], filtersSelection.Park[0]);

  // 2. account for options.priorYear
  const priorYears_Ranges = {};
  for (const currentPriorYear of options.priorYears) {
    priorYears_Ranges[currentPriorYear] = CY_Ranges.map((range) => [
      range[0] + priorYears[currentPriorYear].deltaDays * 24 * 3600,
      range[1] + priorYears[currentPriorYear].deltaDays * 24 * 3600,
    ]);
  }

  const dateRanges = { CY: CY_Ranges, ...priorYears_Ranges };

  // 3. Make the requests
  const weatherDataRaw = await europaAPI.getWeather(token, filtersSelection.Park[0], dateRanges, options);

  // 4. Add colors
  const weatherDataRawWithColors = weatherDataRaw.map((yearData) => ({ ...yearData, color: priorYears[yearData.year].color }));

  // 5. Account for options
  // weatherDataType (fullDay vs. relevantHours)
  const weatherData = weatherDataRawWithColors.map((yearData) => ({
    ...yearData,
    data: yearData.data.map((el) => ({ Date: el.Date, ...el[options.weatherDataType] })),
  }));

  // 6. Make sure all data arrays are the correct length
  const arraysOfDates = {};
  for (const period of Object.keys(dateRanges)) {
    const arrayOfDates = dateRanges[period].map((range) => {
      const currentArrayOfDates = [range[0]];
      while (currentArrayOfDates[currentArrayOfDates.length - 1] + 24 * 3600 < range[1]) {
        currentArrayOfDates.push(currentArrayOfDates[currentArrayOfDates.length - 1] + 24 * 3600);
      }
      return currentArrayOfDates;
    });
    arraysOfDates[period] = arrayOfDates
      .flat()
      .sort()
      .map((epoch) => format(new Date(epoch * 1000), 'yyyy-MM-dd'));
    arraysOfDates[period] = arraysOfDates[period].filter((value, index, self) => self.indexOf(value) === index); // formula to remove duplicates
  }

  const weatherData_filled = weatherData.map((yearData) => ({
    ...yearData,
    data: arraysOfDates[yearData.year].map((currentDate) => yearData.data.find((el) => el.Date === currentDate) || { Date: currentDate }),
  }));

  // Use the dates of CY for other periods
  const CY_Dates = weatherData_filled.find((el) => el.year === 'CY').data.map((el) => el.Date);
  const weatherData_datesReplaced = weatherData_filled.map((yearData) => ({
    ...yearData,
    data: yearData.data.map((el, i) => ({
      ...el,
      Date: CY_Dates[i],
    })),
  }));

  // 4. (TODO) Account for options.timeGrouping
  if (options.timeGrouping !== 'Day') {
    const weatherWeights = weatherCodeWeights.filter((el) => el.Park === filtersSelection.Park[0]);
    const weatherData_grouped = weatherData_datesReplaced.map((yearData) => {
      const data_withDayOfWeek = yearData.data.map((el) => ({
        ...el,
        dayOfWeek: getISODay(new Date(el.Date)),
      }));
      const data_withWeatherWeights = data_withDayOfWeek.map((el) => ({
        ...el,
        weatherWeight: weatherWeights.find((subEl) => subEl.Day === el.dayOfWeek).Weight,
      }));

      let data_withTimeGroup = data_withWeatherWeights;
      switch (options.timeGrouping) {
        case 'Week':
          data_withTimeGroup = data_withWeatherWeights.map((el) => ({ ...el, timeGroup: format(lastDayOfISOWeek(new Date(el.Date)), 'yyyy-MM-dd') }));
          break;
        case 'Month':
          data_withTimeGroup = data_withWeatherWeights.map((el) => ({ ...el, timeGroup: format(new Date(el.Date), 'MM-yyyy') }));
          break;
        case 'Quarter':
          data_withTimeGroup = data_withWeatherWeights.map((el) => ({ ...el, timeGroup: format(new Date(el.Date), 'QQQ-yyyy') }));
          break;
        case 'Year':
          data_withTimeGroup = data_withWeatherWeights.map((el) => ({ ...el, timeGroup: format(new Date(el.Date), 'yyyy') }));
          break;

        default:
          break;
      }

      // Group by timeGroup
      const timeGroups = [...new Set(data_withTimeGroup.map((el) => el.timeGroup))];
      const groupedData = timeGroups
        .map((timeGroup) =>
          data_withTimeGroup
            .filter((el) => el.timeGroup === timeGroup)
            .reduce(
              (acc, cur) => {
                if (!cur.temp) return acc;
                return {
                  Date: cur.timeGroup,
                  cloudcover: acc.cloudcover + cur.cloudcover * cur.weatherWeight,
                  feelslike: acc.feelslike + cur.feelslike * cur.weatherWeight,
                  feelslikemax: acc.feelslikemax + cur.feelslikemax * cur.weatherWeight,
                  feelslikemin: acc.feelslikemin + cur.feelslikemin * cur.weatherWeight,
                  precip: acc.precip + cur.precip,
                  snow: acc.snow + cur.snow,
                  snowdepth: acc.snowdepth + cur.snowdepth * cur.weatherWeight,
                  temp: acc.temp + cur.temp * cur.weatherWeight,
                  tempmax: acc.tempmax + cur.tempmax * cur.weatherWeight,
                  tempmin: acc.tempmin + cur.tempmin * cur.weatherWeight,
                  windgust: acc.windgust + cur.windgust * cur.weatherWeight,
                  windspeed: acc.windspeed + cur.windspeed * cur.weatherWeight,
                  sumOfWeatherWeights: acc.sumOfWeatherWeights + cur.weatherWeight || 0,
                };
              },
              {
                Date: timeGroup,
                cloudcover: 0,
                feelslike: 0,
                feelslikemax: 0,
                feelslikemin: 0,
                precip: 0,
                snow: 0,
                snowdepth: 0,
                temp: 0,
                tempmax: 0,
                tempmin: 0,
                windgust: 0,
                windspeed: 0,
                sumOfWeatherWeights: 0,
              },
            ),
        )
        .map((dataGroup) => ({
          ...dataGroup,
          cloudcover: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.cloudcover / dataGroup.sumOfWeatherWeights : 0,
          feelslike: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.feelslike / dataGroup.sumOfWeatherWeights : 0,
          feelslikemax: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.feelslikemax / dataGroup.sumOfWeatherWeights : 0,
          feelslikemin: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.feelslikemin / dataGroup.sumOfWeatherWeights : 0,
          snowdepth: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.snowdepth / dataGroup.sumOfWeatherWeights : 0,
          temp: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.temp / dataGroup.sumOfWeatherWeights : 0,
          tempmax: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.tempmax / dataGroup.sumOfWeatherWeights : 0,
          tempmin: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.tempmin / dataGroup.sumOfWeatherWeights : 0,
          windgust: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.windgust / dataGroup.sumOfWeatherWeights : 0,
          windspeed: dataGroup.sumOfWeatherWeights > 0 ? dataGroup.windspeed / dataGroup.sumOfWeatherWeights : 0,
        }));

      return {
        ...yearData,
        data: groupedData,
      };
    });
    // console.log(weatherData_grouped);
    return weatherData_grouped;
  }

  // 5. (TODO) Account for options.toggleCumulative
  if (options.toggleCumulative) return [];

  // console.log(weatherData_datesReplaced);

  return weatherData_datesReplaced;
};

const updateOption = ({ module, optionName, newValue }) => ({
  type: europaActionTypes.UPDATE_OPTION,
  module,
  optionName,
  newValue,
});

const updateFilter = ({ module, filterName, newItems }) => {
  const sortOrder = dimSortings[filterName];
  const newItemsSorted = sortPerList(newItems, sortOrder);

  return {
    type: europaActionTypes.UPDATE_FILTER,
    module,
    filterName,
    newItems: newItemsSorted,
  };
};

const toggleFileToDownload = ({ module, fileName }) => ({
  type: europaActionTypes.TOGGLE_FILE_TO_DOWNLOAD,
  module,
  fileName,
});

export default {
  downloadFullData,
  downloadRunDetails,
  getWeatherData,
  updateOption,
  updateFilter,
  toggleFileToDownload,
};
