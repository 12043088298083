import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, H6 } from '@blueprintjs/core';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import './ScreenshotOverlay.css';
import ScreenshotInOverlay from './ScreenshotInOverlay/ScreenshotInOverlay';

const ScreenshotOverlay = ({ isOpen, handleClose, imgData, updateNbOfLocalScreenshotKeys }) => {
  if (!imgData[0]) return null;
  return (
    <Dialog
      className="dialogContainer"
      isOpen={isOpen}
      onClose={handleClose}
      transitionDuration={-1}
      canEscapeKeyClose
      // usePortal={navigator.userAgent.search('Firefox') === -1}
    >
      {imgData.map((img) => (
        <ScreenshotInOverlay key={img.key} img={img} updateNbOfLocalScreenshotKeys={updateNbOfLocalScreenshotKeys} />
      ))}
    </Dialog>
  );
};

ScreenshotOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  imgData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      width: PropTypes.number,
      height: PropTypes.number,
      src: PropTypes.string,
      screenshot: screenshotPropTypes,
    }),
  ).isRequired,
  updateNbOfLocalScreenshotKeys: PropTypes.func.isRequired,
};

export default ScreenshotOverlay;
