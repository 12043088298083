import React from 'react';
import PropTypes from 'prop-types';
import { H4, HTMLTable } from '@blueprintjs/core';
import './DataTable.css';

const DataTable = (props) => (
  <div className="data-table">
    <H4>{props.title}</H4>
    <HTMLTable bordered striped interactive condensed>
      <thead>
        <tr>
          {props.tableHeaders.map((el) => <th key={el}>{el}</th>)}
        </tr>
      </thead>
      <tbody>
        {props.tableData.map((tableRow) => (
          <tr key={tableRow.join('')}>
            {tableRow.map((el, i) => <td key={`${props.tableHeaders[i]} ${el}`}>{el}</td>)}
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  </div>
);

DataTable.propTypes = {
  title: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default DataTable;
