const sortPerList = (arr, sortOrder) => {
  if (!sortOrder) return arr.sort();

  const itemsInSortOrder = sortOrder.filter((el) => arr.includes(el));
  const itemsNotInSortOrder = arr.filter((el) => !itemsInSortOrder.includes(el));
  return [...itemsInSortOrder, ...itemsNotInSortOrder];
};

const calculateMetric = (el, metric) => {
  if (metric === 'Conversion Rate') return el.transactions / el.sessions;
  return el[metric];
};

export { sortPerList, calculateMetric };
