import React from 'react';
import PropTypes from 'prop-types';
import { H5 } from '@blueprintjs/core';
import localforage from 'localforage';
import DataUpload from './DataUpload';

const DataUploadContainer = (props) => {
  const fileReader = new FileReader();
  fileReader.onload = () => {
    const fileContent = fileReader.result;
    const firstRow = fileContent.split('\n')[0];
    const fields = firstRow.split(',').map((el) => el.replace('\r', '').replace('\n', ''));
    // console.log(fields);
    const nbFields = fields.length;
    const fileContent_obj = fileContent
      .split('\n')
      .slice(1)
      .map((el) => {
        const row_obj = {};
        for (let i = 0; i < nbFields; i += 1) {
          row_obj[fields[i]] = el.replace('\r', '').replace('\n', '').split(',')[i];
        }
        return row_obj;
      });
    // console.log(fileContent_obj);
    if (
      firstRow ===
      'Date,Park,ParkList,Pass_Type,Pass_SubType,Pass_Season,PassTier,PassDescr,ProductName,Type,Base_Total_CY,Base_IC_CY,Base_OOC_CY,Base_Total_AllParks_CY,Base_IC_AllParks_CY,Base_OOC_AllParks_CY,CompPass\r'
    ) {
      localforage.setItem('Europa_Pass_Base', fileContent_obj);
    } else if (firstRow === 'ExpirationDate,Park,ParkList,Tier,ProductCategory,Expirations\r') {
      localforage.setItem('Europa_Pass_FPexpirations', fileContent_obj);
    } else if (firstRow === 'Date,OrigPassBarCode,Park\r') {
      localforage.setItem('Europa_Pass_FPARrefunds', fileContent_obj);
    } else if (firstRow === 'Date,parkList,Tier,Type,Cancelled_OOC,Cancelled_IC,Reinstated_OOC,Reinstated_IC\r') {
      localforage.setItem('Europa_Pass_EZcancellations', fileContent_obj);
    } else if (
      firstRow ===
      'ItemID,State,ParkList,SaleDate,SaleYear,SaleMonth,SaleYearMonth,ItemStatus,PassDescription,PassType,FPAR_status,HasLikelyRefund,CommittedAmount,TotalCollectedAmt,Ratio,EffectivePaymentsCollected,PaymentsCollected,PaymentAmt,PriceIncrease\r'
    ) {
      localforage.setItem('Europa_Pass_EZrenewals', fileContent_obj);
    } else {
      console.log(fileContent.split('\n')[0]);
      alert('Unknown file type');
    }
  };

  const handleFileDrop = (files, type) => {
    if (files.length > 1) {
      alert('Please select a single file');
      return;
    }
    fileReader.readAsText(files[0]);
  };

  return (
    <>
      <H5>Upload files</H5>
      <div className="dataUploadContainer">
        <DataUpload title="Pass Base" handleFileDrop={handleFileDrop} />
        <DataUpload title="FP Expirations" handleFileDrop={handleFileDrop} />
        <DataUpload title="EZ Cancellations" handleFileDrop={handleFileDrop} />
        <DataUpload title="FPAR Refunds" handleFileDrop={handleFileDrop} />
        <DataUpload title="FPAR Renewals" handleFileDrop={handleFileDrop} />
      </div>
    </>
  );
};

export default DataUploadContainer;
