import React from 'react';
import PropTypes from 'prop-types';
import { HTMLTable } from '@blueprintjs/core';
import numeral from 'numeral';
import './WeeklyReportTable.css';

const WeeklyReportEmailTable = (props) => {
  // console.log(props.weeklyEmailReportData);
  if (!props.weeklyEmailReportData.CY) {
    return <p>Loading...</p>;
  }

  const buildRow = (source, metric, subtotal = false) => {
    const getCell = (period, metricType = 'CY') => {
      // Filter for period only
      const period_Data_CY = props.weeklyEmailReportData.CY.find((el) => el.period === period) || [];
      const period_Data_PY = props.weeklyEmailReportData.PY.find((el) => el.period === period) || [];

      // Filter for source only
      const period_source_Data_CY = subtotal
        ? period_Data_CY.data.reduce((acc, curr) => ({ [metric]: acc[metric] + curr[metric] }), { [metric]: 0 })
        : period_Data_CY.data.find((el) => el.Source === source) || [];
      const period_source_Data_PY = subtotal
        ? period_Data_PY.data.reduce((acc, curr) => ({ [metric]: acc[metric] + curr[metric] }), { [metric]: 0 })
        : period_Data_PY.data.find((el) => el.Source === source) || [];

      if (metricType === 'vs. PY units') {
        const value = period_source_Data_CY[metric] - period_source_Data_PY[metric];
        return <td className={value >= 0 ? '' : 'negative'}>{numeral(value).format('+0,0')}</td>;
      }
      if (metricType === 'vs. PY %') {
        const value = period_source_Data_CY[metric] / period_source_Data_PY[metric] - 1;
        return <td className={value >= 0 ? '' : 'negative'}>{numeral(value).format('+0.0%')}</td>;
      }
      const value = period_source_Data_CY[metric];
      return <td>{numeral(value).format('0,0')}</td>;
    };

    return (
      <tr className={subtotal ? 'totalRow' : ''}>
        {source === 'SFMC' && (
          <td rowSpan="4" className="rowHeader">
            {metric[0].toUpperCase() + metric.slice(1)}
          </td>
        )}
        <td className="rowHeader">{source}</td>

        {getCell('M-1', 'CY')}
        {getCell('MTD', 'CY')}
        {getCell('W-2', 'CY')}
        {getCell('L7', 'CY')}
        {getCell('M-1', 'vs. PY units')}
        {getCell('MTD', 'vs. PY units')}
        {getCell('W-2', 'vs. PY units')}
        {getCell('L7', 'vs. PY units')}
        {getCell('M-1', 'vs. PY %')}
        {getCell('MTD', 'vs. PY %')}
        {getCell('W-2', 'vs. PY %')}
        {getCell('L7', 'vs. PY %')}
      </tr>
    );
  };

  return (
    <div className="weeklyReportTable" width="100%">
      <HTMLTable bordered interactive width="100%" style={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th> </th>
            <th> </th>
            <th colSpan="4">2024</th>
            <th colSpan="4">vs. 2023 units</th>
            <th colSpan="4">vs. 2023 %</th>
          </tr>
          <tr>
            <th> </th>
            <th>Source</th>
            <th>M-1</th>
            <th>MTD</th>
            <th>W-2</th>
            <th>L7</th>
            <th>M-1</th>
            <th>MTD</th>
            <th>W-2</th>
            <th>L7</th>
            <th>M-1</th>
            <th>MTD</th>
            <th>W-2</th>
            <th>L7</th>
          </tr>
        </thead>
        <tbody>
          {buildRow('SFMC', 'users')}
          {buildRow('Wunderkind', 'users')}
          {buildRow('(Other)', 'users')}
          {buildRow('Total', 'users', true)}
          {buildRow('SFMC', 'transactions')}
          {buildRow('Wunderkind', 'transactions')}
          {buildRow('(Other)', 'transactions')}
          {buildRow('Total', 'transactions', true)}
        </tbody>
      </HTMLTable>
    </div>
  );
};

WeeklyReportEmailTable.propTypes = {
  weeklyEmailReportData: PropTypes.shape({
    CY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
    PY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
  }).isRequired,
};

export default WeeklyReportEmailTable;
