/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { calculateMetric } from 'AppModules/Europa/utils/utils';
import { metricFormatMatrix, metricFormatMatrix_exact } from 'AppModules/Europa/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'AppModules/Europa/Components/DataTable/DataTable';
import europaActions from 'AppModules/Europa/Actions/europaActions';
import LinesChart from './LinesChart';

const LinesChartContainer = (props) => {
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((store) => store.userReducer.userSettings);
  const { options } = useSelector((store) => store.europaReducer[props.module]);

  const title = props.title || Object.values(props.metrics).join(' & ');

  const dataUnflattened = props.data.map((yearData) => {
    const metricDatas = Object.entries(props.metrics).map(([metric, chartType]) => {
      const xData = [];
      const yData = [];
      const yText = [];
      const deltaPct = [];
      const text = [];
      for (const [j, el] of yearData.data.entries()) {
        xData.push(el.Date);
        const dataValue = calculateMetric(el, metric);
        yData.push(dataValue);
        yText.push(numeral(dataValue).format(metricFormatMatrix_exact[metric])); // for Tables
        let currentDeltaPct = [];
        if (yearData.year === 'CY') {
          currentDeltaPct = props.data
            .filter((arr) => arr.year !== 'CY')
            .map((priorYearData) => ({
              year: priorYearData.year,
              value: dataValue / calculateMetric(priorYearData.data[j] || {}, metric),
            }));
          deltaPct.push(currentDeltaPct);
        }
        text.push(
          [
            `<b>${el.Date}</b>`,
            numeral(dataValue).format(metricFormatMatrix[metric]),
            ...currentDeltaPct.map((deltaEl) => `${Math.round(100 * deltaEl.value)}% of ${deltaEl.year}`),
            `Weather Code: ${numeral(calculateMetric(el, 'Weather Code')).format(metricFormatMatrix_exact['Weather Code'])}`,
          ].join('<br>'),
        );
      }

      // Remove trailing zeroes (so the line stops instead of dropping to zero)
      let lastValue = yData.slice(-1)[0];
      while (lastValue === 0) {
        yData.pop();
        lastValue = yData.slice(-1)[0];
      }

      const output = {
        chartType,
        x: xData,
        y: yData,
        yText,
        text,
        deltaPct,
        name: yearData.year,
        color: yearData.color,
      };

      return output;
    });
    return metricDatas;
  });

  const data = dataUnflattened.flat();

  const tableHeaders = ['Date', ...data.map((el, i) => (i === 0 ? el.name : [el.name, `% of ${el.name}`])).flat()];
  const tableData = data[0]
    ? data[0].x.map((date, i) => [
        date,
        data[0].yText[i],
        ...data
          .slice(1)
          .map((el, j) => {
            const a = data[0].deltaPct[i];
            const b = a[j] ? a[j].value : 0; // using a and b variables because React devtools bug causes an error un the below line (at [j])
            // const deltaPctValue = props.chartData[0].deltaPct[i][j].value;
            return [el.yText[i], numeral(b).format('0.0%')];
          })
          .flat(),
      ])
    : [];

  const copyData = () => props.copyDataToClipboard(tableHeaders, tableData);

  const handleUpdateOptions = ({ optionName, newValue }) => {
    dispatch(europaActions.updateOption({ module: props.module, optionName, newValue }));
  };

  return options.toggleTable ? (
    <DataTable title={title} tableHeaders={tableHeaders} tableData={tableData} isDarkMode={isDarkMode} />
  ) : (
    data.length > 0 && (
      <LinesChart
        title={title}
        data={data}
        copyData={copyData}
        height={props.height}
        isDarkMode={isDarkMode}
        options={options}
        handleUpdateOptions={handleUpdateOptions}
        type={props.type}
        tempUnit={options.tempUnit}
      />
    )
  );
};

LinesChartContainer.propTypes = {
  module: PropTypes.string.isRequired,
  title: PropTypes.string,
  metrics: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      year: PropTypes.string,
    }),
  ).isRequired,
  copyDataToClipboard: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  type: PropTypes.string,
};

LinesChartContainer.defaultProps = {
  title: null,
  type: 'standard',
};

export default LinesChartContainer;
