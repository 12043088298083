import React from 'react';
import PropTypes from 'prop-types';
import '../Europa.css';
import './Pass.css';
import { H5, H6 } from '@blueprintjs/core';
import DataUploadContainer from './DataUpload/DataUploadContainer';
import FiltersContainer from '../Filters/FiltersContainer';
import OptionsContainer from '../Options/OptionsContainer';

const Pass = (props) => (
  <div className="Pass">
    <DataUploadContainer />
    <H5 style={{ marginTop: '10px' }}>Loaded Files</H5>
    <div className="dataInfo">
      <div className="singleFileInfo">
        <H6>Base</H6>
        <p>Last Date: {props.dataInfo.Europa_Pass_Base?.lastDate}</p>
        <p>{props.dataInfo.Europa_Pass_Base?.nbRows} rows</p>
      </div>
      <div className="singleFileInfo">
        <H6>FP Expirations</H6>
        <p>Last Date: {props.dataInfo.Europa_Pass_FPexpirations?.lastDate}</p>
        <p>{props.dataInfo.Europa_Pass_FPexpirations?.nbRows} rows</p>
      </div>
      <div className="singleFileInfo">
        <H6>EZpay Cancellations</H6>
        <p>Last Date: {props.dataInfo.Europa_Pass_EZcancellations?.lastDate}</p>
        <p>{props.dataInfo.Europa_Pass_EZcancellations?.nbRows} rows</p>
      </div>
      <div className="singleFileInfo">
        <H6>FPAR Refunds</H6>
        <p>Last Date: {props.dataInfo.Europa_Pass_FPARrefunds?.lastDate}</p>
        <p>{props.dataInfo.Europa_Pass_FPARrefunds?.nbRows} rows</p>
      </div>
      <div className="singleFileInfo">
        <H6>FPAR Renewals</H6>
        <p>Last Date: {props.dataInfo.Europa_Pass_FPARrenewals?.lastDate}</p>
        <p>{props.dataInfo.Europa_Pass_FPARrenewals?.nbRows} rows</p>
      </div>
    </div>

    <div className="topRow">
      <OptionsContainer module="pass" dataInfo={props.dataInfo} />
      <FiltersContainer module="pass" dataInfo={props.dataInfo} filterValues={props.filterValues} />
    </div>
  </div>
);

Pass.propTypes = {
  dataInfo: PropTypes.objectOf(
    PropTypes.shape({
      nbRows: PropTypes.number.isRequired,
      fields: PropTypes.arrayOf(PropTypes.string).isRequired,
      lastDate: PropTypes.string,
    }),
  ).isRequired,
  filterValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default Pass;
