import React from 'react';
import PropTypes from 'prop-types';
import Plotly from 'plotly.js-cartesian-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import { Button } from '@blueprintjs/core';

const Plot = createPlotlyComponent(Plotly);

const HeatmapChart = (props) => (
  <div className="chart">
    <Plot
      name={props.metric}
      data={props.data.reverse().map((data) => ({ // Reverse so that the CY curve appears on top on the chart
        x: data.x,
        y: data.y,
        z: data.name === 'CY' ? data.z : null,
        zmin: 0,
        zmax: 3,
        name: data.name,
        // mode: data.chartMode,
        type: 'heatmap',
        colorscale: data.colorscale,
        showscale: false,
        marker: {
          size: 6,
          line: {
            width: 1,
            color: props.isDarkMode ? '#394B59' : '#f5f8fa',
          },
        },
        line: {
          color: props.isDarkMode ? data.color.dark : data.color.dark,
          width: data.name === 'CY' ? 2 : 1 },
        hoverinfo: 'text',
        text: data.z,
        textfont: { size: 2, color: 'red' },
      }))}
      layout={{
        width: (window.innerWidth - 120) / 2,
        height: props.data[0].chartHeight,
        title: {
          text: props.metric,
          font: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
        },
        showlegend: true,
        legend: {
          font: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
        },
        margin: {
          t: 50,
          b: 20,
          l: 75,
          r: 120,
        },
        xaxis: {
          showgrid: false,
          tickfont: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
          visible: false,
        },
        yaxis: {
          showgrid: true,
          tickfont: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
          rangemode: 'tozero',
          zeroline: true,
          zerolinecolor: props.isDarkMode ? '#778793' : '#5C7080',
          automargin: true,
          gridcolor: props.isDarkMode ? '#4A5D6C' : '#ced9e0',
        },
        paper_bgcolor: props.isDarkMode ? '#394B59' : '#f5f8fa',
        plot_bgcolor: props.isDarkMode ? '#394B59' : '#f5f8fa',
      }}
      config={{
        displayModeBar: false,
        // responsive: true,
      }}
    />
    <div className="chartActions">
      <Button icon="clipboard" minimal onClick={props.copyData} />
    </div>
  </div>
);

HeatmapChart.propTypes = {
  metric: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.arrayOf(PropTypes.string),
    y: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    z: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    name: PropTypes.string.isRequired,
    chartHeight: PropTypes.number.isRequired,
    color: PropTypes.shape({
      dark: PropTypes.string,
      light: PropTypes.string,
    }),
  })).isRequired,
  copyData: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};

export default HeatmapChart;
