import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Drawer, Intent, ProgressBar, Spinner, Tag } from '@blueprintjs/core';
import { yearsToDownload } from '../utils/constants';

const Data = (props) => (

  <div className="dataContainer">
    <div className="runDetails">
      {props.downloadProgress === null && !props.runDetails.isLoading && !props.runDetails.isError && (
        <Tag
          icon={props.dataUpdateStatus.upToDate ? 'updated' : 'outdated'}
          intent={props.dataUpdateStatus.upToDate ? Intent.SUCCESS : Intent.WARNING}
          minimal
          round
        >
          {props.dataUpdateStatus.upToDate ? 'Up to date' : 'Update available'}
        </Tag>
      )}
      {props.runDetails.isLoading && <Spinner size={20} />}
    </div>
    {props.downloadProgress !== null
      ? (
        <>
          <span className="downloadProgressText">{`Downloading latest data (${Math.floor(props.downloadProgress * 100)}%)`}</span>
          <ProgressBar
            className="progressBar"
            value={props.downloadProgress}
            intent={Intent.PRIMARY}
          />
        </>
      )
      : (
        <>
          {/* <Button className="dataParametersButton" onClick={() => props.toggleDataParametersDialog(true)} icon="more" minimal /> */}
          <Button
            className="refreshButton"
            onClick={props.refreshData}
            intent={Intent.PRIMARY}
            icon="refresh"
            // text={props.refreshButtonText}
            text="Refresh Data"
          />
        </>
      )}
    <Drawer
      title="Data Parameters"
      isOpen={props.dataParametersDialogIsOpen}
      canEscapeKeyClose
      canOutsideClickClose
      onClose={() => props.toggleDataParametersDialog(false)}
      size="400px"
    >
      <div className="dataParametersContent">
        <h5>Files to download</h5>
        {yearsToDownload.map((year) => (
          <Checkbox
            key={year}
            checked={props.dataParameters.filesToDownload.find((el) => el.fileName === year).value}
            onChange={() => props.toggleFileToDownload(year)}
            label={year}
          />
        ))}
      </div>
    </Drawer>
  </div>
);

Data.propTypes = {
  dataUpdateStatus: PropTypes.shape({
    lastDateRemote: PropTypes.string,
    lastDateLocal: PropTypes.string,
    upToDate: PropTypes.bool,
  }).isRequired,
  runDetails: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    data: PropTypes.objectOf(PropTypes.string),
    err: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  refreshData: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
  // refreshButtonText: PropTypes.string.isRequired,
  toggleDataParametersDialog: PropTypes.func.isRequired,
  dataParametersDialogIsOpen: PropTypes.bool.isRequired,
  dataParameters: PropTypes.shape({
    filesToDownload: PropTypes.arrayOf(PropTypes.shape({
      fileName: PropTypes.string,
      value: PropTypes.bool,
    })),
  }).isRequired,
  toggleFileToDownload: PropTypes.func.isRequired,
};

Data.defaultProps = {
  downloadProgress: null,
};

export default Data;
