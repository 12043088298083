/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ganymedeActions from 'AppModules/Ganymede/Actions/ganymedeActions';
import { dimSortings, filters } from 'AppModules/Ganymede/utils/constants';
import { sortPerList } from 'AppModules/Ganymede/utils/util';
import ExplorerFilters from './ExplorerFilters';

const ExplorerFiltersContainer = (props) => {
  const [filters_updated, setFilters_updated] = React.useState(filters);

  // When Park Group or Park filterSeletion changes, update Park and Page filter values
  React.useEffect(() => {
    // 1.1 Park Group
    const ParkGroupValues = [...new Set(Object.values(props.pages).map((pageParams) => pageParams.parkGroup))];

    // 1.2 Park
    const pagesToConsider_Park =
      props.filtersSelection['Park Group']?.length > 0
        ? Object.values(props.pages).filter((page) => props.filtersSelection['Park Group']?.includes(page.parkGroup))
        : props.pages;
    const ParkValues = [...new Set(Object.values(pagesToConsider_Park).map((pageParams) => pageParams.park))];
    const ParkValuesSorted = sortPerList(ParkValues, dimSortings.Park);

    // 1.3 Page
    const pagesToConsider_Page =
      props.filtersSelection.Park.length === 0
        ? pagesToConsider_Park
        : Object.values(pagesToConsider_Park).filter((page) => props.filtersSelection.Park.includes(page.park));
    const PageValues = [...new Set(Object.values(pagesToConsider_Page).map((pageParams) => pageParams.page))];
    const PageValuesSorted = sortPerList(PageValues, dimSortings.Pages);

    setFilters_updated({
      ...filters,
      'Park Group': { ...filters['Park Group'], values: ParkGroupValues },
      Park: { ...filters.Park, values: ParkValuesSorted },
      Page: { ...filters.Page, values: PageValuesSorted },
    });
  }, [props.pages, props.filtersSelection['Park Group'], props.filtersSelection.Park]);

  // When filtersSelection change, fetch corresponding list of screenshots from the server
  React.useEffect(() => {
    // if filters.Park is undefined, initial state hasn't been set yet
    const selection = { ...props.filtersSelection };
    if (selection.Park) {
      // if no park is selected, all parks should be in the list (to allow querying on the server, becase park is the DB's primary key)
      if (selection.Park.length === 0) {
        const allParks = [...new Set(Object.values(props.pages).map((pageParams) => pageParams.park))];
        selection.Park = allParks;
      }
      props.updateScreenshots(props.token, selection);
    }

    // TODO: filters should be in the list of dependencies. Need to implement system where updateScreenshots is not triggered if filters didn't change
  }, [props.filtersSelection]);

  return (
    <ExplorerFilters
      filters={filters_updated}
      filtersSelection={props.filtersSelection}
      handleUpdateFilter={props.updateFilter}
      handleAddDateFilter={props.addDateFilter}
      handleRemoveDateFilter={props.removeDateFilter}
      handleChangeDateFilterType={props.changeDateFilterType}
    />
  );
};

ExplorerFiltersContainer.propTypes = {
  token: PropTypes.string.isRequired,
  filtersSelection: PropTypes.object,
  updateFilter: PropTypes.func.isRequired,
  addDateFilter: PropTypes.func.isRequired,
  removeDateFilter: PropTypes.func.isRequired,
  changeDateFilterType: PropTypes.func.isRequired,
  updateScreenshots: PropTypes.func.isRequired,
  pages: PropTypes.object.isRequired,
};

ExplorerFiltersContainer.defaultProps = {
  filtersSelection: {},
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
  filtersSelection: store.ganymedeReducer.explorer.filtersSelection,
  pages: store.ganymedeReducer.pages,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: (filterName, filterValues) => dispatch(ganymedeActions.updateFilter('explorer', filterName, filterValues)),
  addDateFilter: () => dispatch(ganymedeActions.addDateFilter('explorer')),
  removeDateFilter: (additionalDateFilterID) => dispatch(ganymedeActions.removeDateFilter('explorer', additionalDateFilterID)),
  changeDateFilterType: (additionalDateFilterID, value) => dispatch(ganymedeActions.changeDateFilterType('explorer', additionalDateFilterID, value)),
  updateScreenshots: (token, filtersSelection) => dispatch(ganymedeActions.updateScreenshots(token, filtersSelection)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExplorerFiltersContainer);
