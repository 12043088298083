import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import ManageContainer from './Manage/ManageContainer';
import ExplorerContainer from './Explorer/ExplorerContainer';
import ganymedeActions from './Actions/ganymedeActions';
import PricesContainer from './Prices/PricesContainer';

const GanymedeContainer = () => {
  const { token } = useSelector((store) => store.authReducer);
  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  // When mounting: fetch the list of pages
  React.useEffect(() => {
    stableDispatch(ganymedeActions.getPageList(token));
  }, [stableDispatch, token]);

  return (
    <div className="ganymede">
      <Switch>
        <Route exact path="/Ganymede/Manage" component={ManageContainer} />
        <Route exact path="/Ganymede/Explorer" component={ExplorerContainer} />
        <Route path="/Ganymede/Explorer/:screenshotKeys" component={ExplorerContainer} />
        <Route exact path="/Ganymede/Prices" component={PricesContainer} />
      </Switch>
    </div>
  );
};

export default GanymedeContainer;
