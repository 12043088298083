import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { priorYears, timeGroupings } from '../utils/constants';
import Options from './Options';
import ioActions from '../Actions/ioActions';
import { sortPerList } from '../utils/utils';

const OptionsContainer = (props) => {
  const dispatch = useDispatch();

  const options = useSelector((store) => store.ioReducer[props.module].options[props.subModule]);
  const globalOptions = useSelector((store) => store.ioReducer[props.module].options.global);

  const handleUpdateOptions = ({ isGlobalOption = false, optionName, newValue }) => {
    // for the priorYears, sort them first
    const PY_sortOrder = Object.keys(priorYears);
    const newValueSorted = typeof newValue === 'string' || typeof newValue === 'boolean' ? newValue : sortPerList(newValue, PY_sortOrder);

    // const newValueSorted = newValue;
    dispatch(
      ioActions.updateOption({
        module: props.module,
        subModule: isGlobalOption ? 'global' : props.subModule,
        optionName,
        newValue: newValueSorted,
      }),
    );
  };

  return (
    <Options priorYears={priorYears} timeGroupings={timeGroupings} options={options} globalOptions={globalOptions} handleUpdateOptions={handleUpdateOptions} />
  );
};

OptionsContainer.propTypes = {
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
};

export default OptionsContainer;
