import React from 'react';
import PropTypes from 'prop-types';
import MyMultiSelect from 'Components/MyMultiSelect/MyMultiSelect';
import MySelect from 'Components/MySelect/MySelect';

const Filters = (props) => (
  <div className="filters">
    {Object.keys(props.filterValues).map((filterName) => (
      filterName === 'Park'
        ? (
          <section
            key={filterName}
            className={filterName}
          >
            <h4>{filterName}</h4>
            <MySelect
              width={80}
              items={props.filterValues[filterName]}
              activeItem={props.filtersSelection[filterName] ? props.filtersSelection[filterName][0] : props.filterValues[filterName][0]}
              handleItemSelect={(newItems) => props.handleUpdateFilter(filterName, [newItems])}
              filterable={false}
              fill
            />
          </section>
        )
        : (
          <section
            key={filterName}
            className={filterName}
          >
            <h4>{filterName}</h4>
            <MyMultiSelect
              items={props.filterValues[filterName]}
              selectedItems={props.filtersSelection[filterName]}
              updateSelectedItems={(newItems) => props.handleUpdateFilter(filterName, newItems)}
              ctrlSelectMode
            />
          </section>
        )
    ))}
  </div>
);

Filters.propTypes = {
  filterValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  filtersSelection: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
};

export default Filters;
