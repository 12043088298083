/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import MyBreadCrumbs from 'Components/MyBreadCrumbs/MyBreadCrumbs';
import BulkCategoryDefinition from './BulkCategoryDefinition/BulkCategoryDefinition';
import ListCategoryDefinitionContainer from './ListCategoryDefinition/ListCategoryDefinitionContainer';
import RunGeneratorContainer from './RunGenerator/RunGeneratorContainer';
import './DataGenerator.css';

const DataGeneratorContainer = ({ categories }) => (
  <div>
    <MyBreadCrumbs
      items={[
        { href: '/', text: 'Home' },
        { href: '/Data', text: 'Data' },
        { href: '/Data/Data Generator', text: 'Data Generator' },
      ]}
    />
    <RunGeneratorContainer categories={categories} />
    <div className="CategoryDefinitions">
      {Object.keys(categories).map((categoryName) =>
        categories[categoryName].type === 'bulk' ? (
          <BulkCategoryDefinition key={categoryName} categoryName={categoryName} category={categories[categoryName]} />
        ) : (
          <ListCategoryDefinitionContainer key={categoryName} categoryName={categoryName} category={categories[categoryName]} />
        ),
      )}
    </div>
  </div>
);

const mapStateToProps = (store) => ({
  categories: store.dataGeneratorReducer.dataGeneratorParameters.categories,
});

export default connect(mapStateToProps)(DataGeneratorContainer);
