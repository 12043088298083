/* eslint-disable no-console */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import localforage from 'localforage';
import { Intent, Toaster } from '@blueprintjs/core';
import SalesAttendance from './SalesAttendance';
import europaActions from '../Actions/europaActions';
import { convertWeatherData } from '../utils/utils';
import { filterDimensions_salesAtt } from '../utils/constants';

const dataEngineToaster = Toaster.create();

const SalesAttendanceContainer = () => {
  const token = useSelector((store) => store.authReducer.token || '');

  // const [latestRunDetails, setLatestRunDetails] = React.useState({});
  const [downloadProgress, setDownloadProgress] = React.useState();
  const [dataInfo, setDataInfo] = React.useState({});
  const [filterValues, setFilterValues] = React.useState({});
  const [filteredData, setFilteredData] = React.useState([]);
  const [weatherData, setWeatherData] = React.useState([]);

  const { options, filtersSelection, dataParameters } = useSelector((store) => store.europaReducer.salesAttendance);

  const dataEngineWorker = React.useMemo(() => new Worker(new URL('../utils/dataEngineWorker.js', import.meta.url)), []);

  // When dataEngineWorker changes, deal with it
  React.useEffect(() => {
    dataEngineWorker.onmessage = ({ data }) => {
      const { type, res } = data;
      switch (type) {
        case 'loadData':
          setDataInfo({ Europa_salesAttendance_fullData: res.Europa_salesAttendance_fullData });
          break;
        case 'updateFilterValues':
          setFilterValues(res.Europa_salesAttendance_fullData);
          break;
        case 'updateFilteredData':
          setFilteredData(res);
          break;
        default:
          break;
      }
    };
  }, [dataEngineWorker]);

  // When mounting, load data from localForage
  React.useEffect(() => {
    const asyncLoadLocalData = async () => {
      console.time('Loading local data');
      const localData = (await localforage.getItem('Europa_salesAttendance_fullData')) || [];
      console.timeEnd('Loading local data');
      // console.log(localData[0]);
      if (localData.length > 0) {
        dataEngineWorker.postMessage({ table: 'Europa_salesAttendance_fullData', type: 'loadData', payload: localData });
      } else {
        dataEngineToaster.show({ message: 'No data on this device.', intent: Intent.WARNING, icon: 'warning-sign' });
      }
    };
    asyncLoadLocalData();

    // When unmounting, kill the engineWorker
    // return () => dataEngineWorker.terminate();
  }, []);

  // When dataInfo changes, log it
  // React.useEffect(() => {
  //   console.log(dataInfo);
  // }, [dataInfo]);

  // When data changes, update the filter values
  React.useEffect(() => {
    if (dataInfo.Europa_salesAttendance_fullData?.nbRows > 0) {
      dataEngineWorker.postMessage({
        table: 'Europa_salesAttendance_fullData',
        type: 'updateFilterValues',
        payload: { filterDimensions: filterDimensions_salesAtt },
      });
    }
  }, [dataInfo]);

  // Update filteredData and weatherData when dataInfo, filterValues, filtersSelection, timeGrouping, priorYears or toggleCumulative changes
  React.useEffect(() => {
    if (dataInfo.Europa_salesAttendance_fullData?.nbRows > 0 && Object.entries(filterValues).length > 0 && Object.entries(filtersSelection).length > 0) {
      dataEngineWorker.postMessage({
        table: 'Europa_salesAttendance_fullData',
        type: 'updateFilteredData',
        payload: { table: 'Europa_salesAttendance_fullData', dataInfo, options, filtersSelection, filterValues },
      });

      const asyncGetWeatherData = async () => {
        const currentWeatherData = await europaActions.getWeatherData(token, filtersSelection, options);
        setWeatherData(currentWeatherData);
      };
      asyncGetWeatherData();
    }
  }, [dataInfo, filterValues, filtersSelection, options.timeGrouping, options.priorYears, options.toggleCumulative]);

  const onDownloadProgress = (progressEvent) => {
    setDownloadProgress(progressEvent.loaded / progressEvent.total);
  };

  const refreshData = async () => {
    // eslint-disable-next-line no-unused-vars
    let isSubscribed = true; // needed because the async function could resolve after the component has unmounted

    setDownloadProgress(0); // Set progress to show that download has started (instead of waiting for the first progress event)
    const { data, err } = await europaActions.downloadFullData(token, onDownloadProgress);
    if (!err) {
      dataEngineWorker.postMessage({ table: 'Europa_salesAttendance_fullData', type: 'loadData', payload: data });
      // const res = createSQLtable(data, { table: 'Europa_salesAttendance_fullData' });
      // setDataInfo({ ...dataInfo, Europa_salesAttendance_fullData: res.dataInfo });
      setDownloadProgress(null);
    } else {
      dataEngineToaster.show({ message: `Error downloading the data file: ${err}`, intent: Intent.WARNING, icon: 'warning-sign' });
    }
    return () => {
      isSubscribed = false;
    };
  };

  return (
    <SalesAttendance
      dataInfo={dataInfo}
      refreshData={refreshData}
      downloadProgress={downloadProgress}
      filterValues={filterValues}
      chartData={filteredData}
      weatherData={convertWeatherData(weatherData, { temp: options.tempUnit })}
      dataParameters={dataParameters}
    />
  );
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
});

export default connect(mapStateToProps)(SalesAttendanceContainer);
