import PropTypes from 'prop-types';

const pagePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  URL: PropTypes.string,
  localIP: PropTypes.string,
  nonLocalIP: PropTypes.string,
  email: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  subFolders: PropTypes.arrayOf(
    PropTypes.shape({
      subFolderName: PropTypes.string.isRequired,
      variants: PropTypes.number.isRequired,
    }),
  ),
  compareFromSelector: PropTypes.string,
  compareToSelector: PropTypes.string,
});

const filterPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['list', 'date']).isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
});

const screenshotPropTypes = PropTypes.shape({
  park: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  dateStr: PropTypes.string.isRequired,
  timeStr: PropTypes.string,
  version: PropTypes.string.isRequired,
  key: PropTypes.string,
});

export { pagePropTypes, filterPropTypes, screenshotPropTypes };
