/* eslint-disable no-await-in-loop */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import localforage from 'localforage';
import { Intent, Toaster } from '@blueprintjs/core';
import Pass from './Pass';
import { filterDimensions_passBase, filterDimensions_salesAtt } from '../utils/constants';

const dataEngineToaster = Toaster.create();

const localFiles = ['Europa_Pass_Base', 'Europa_Pass_FPexpirations', 'Europa_Pass_EZcancellations', 'Europa_Pass_FPARrefunds', 'Europa_Pass_FPARrenewals'];

const PassContainer = (props) => {
  const token = useSelector((store) => store.authReducer.token || '');

  const [dataInfo, setDataInfo] = React.useState({});
  const [filterValues, setFilterValues] = React.useState({});
  const [filteredData, setFilteredData] = React.useState([]);

  const { options, filtersSelection, dataParameters } = useSelector((store) => store.europaReducer.pass);

  const dataEngineWorker = React.useMemo(() => new Worker(new URL('../utils/dataEngineWorker.js', import.meta.url)), []);

  // When dataEngineWorker changes, deal with it
  React.useEffect(() => {
    dataEngineWorker.onmessage = ({ data }) => {
      const { type, res } = data;
      switch (type) {
        case 'loadData':
          setDataInfo((currStare) => ({ ...currStare, ...res }));
          break;
        case 'updateFilterValues':
          setFilterValues(res.Europa_Pass_Base);
          break;
        case 'updateFilteredData':
          setFilteredData(res);
          break;
        default:
          break;
      }
    };
  }, [dataEngineWorker]);

  // When mounting, load data from localForage
  React.useEffect(() => {
    const asyncLoadLocalData = async () => {
      console.time('Loading local data');
      for (let i = 0; i < localFiles.length; i += 1) {
        const localData = (await localforage.getItem(localFiles[i])) || [];
        if (localData.length > 0) dataEngineWorker.postMessage({ table: localFiles[i], type: 'loadData', payload: localData });
      }
      console.timeEnd('Loading local data');

      // dataEngineToaster.show({ message: 'No data on this device.', intent: Intent.WARNING, icon: 'warning-sign' });
    };
    asyncLoadLocalData();
    return () => dataEngineWorker.terminate();
  }, []);

  // When data changes, update the filter values
  React.useEffect(() => {
    // for (const file of localFiles) {
    if (dataInfo.Europa_Pass_Base?.nbRows > 0) {
      dataEngineWorker.postMessage({
        table: 'Europa_Pass_Base',
        type: 'updateFilterValues',
        payload: { filterDimensions: filterDimensions_passBase },
      });
    }
    // }
  }, [dataInfo.Europa_Pass_Base]);

  return <Pass dataInfo={dataInfo} filterValues={filterValues} />;
};

export default PassContainer;
