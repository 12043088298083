import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import numeral from 'numeral';
import ButtonWithWarning from 'Components/ButtonWithWarning/ButtonWithWarning';
import { MAX_ITEMS_LIST_COMPARE } from 'AppModules/Ganymede/utils/constants';

const ExplorerControls = (props) => (
  <div className="ExplorerControls">
    <div className="controlButtons">
      {/* <ButtonGroup>
        <Button icon="list" active={!props.toggleGridView} onClick={() => props.handleUpdateControl('toggleGridView', false)} />
        <Button icon="grid-view" active={props.toggleGridView} onClick={() => props.handleUpdateControl('toggleGridView', true)} />
      </ButtonGroup> */}
      <Button
        className="compareScreenshotsButton"
        text="Compare"
        icon="comparison"
        disabled={props.selectedScreenshotKeys.length < 2 || props.selectedScreenshotKeys.length > MAX_ITEMS_LIST_COMPARE}
        onClick={props.handleCompareScreenshots}
      />
      <Button
        className="copyLinkButton"
        text="Copy link"
        icon="link"
        intent={Intent.PRIMARY}
        disabled={props.selectedScreenshotKeys.length < 1 || props.selectedScreenshotKeys.length > MAX_ITEMS_LIST_COMPARE}
        onClick={(e) => props.copyLinkToClipboard(e, props.selectedScreenshotKeys)}
        minimal
      />
      <Button
        className="removeLocalScreenshotsButton"
        text="Remove local copy"
        icon="trash"
        intent={Intent.WARNING}
        onClick={props.handleRemoveLocalScreenshots}
        disabled={props.selectedScreenshotKeys.length === 0}
        minimal
      />
      <ButtonWithWarning
        className="removeAllLocalScreenshotsButton"
        buttonText="Remove all local copies"
        buttonIcon="trash"
        buttonIntent={Intent.DANGER}
        warningBodyText="Are you sure you want to remove all local screenshots? All screenshots will need to be downloaded again"
        warningButtonText="Yes, remove"
        warningIcon="warning-sign"
        warningIntent={Intent.WARNING}
        onConfirm={props.removeAllLocalScreenshotsButton}
        minimal
      />
    </div>
    <p className="screenshotsCounts">
      {`${numeral(props.totalNbOfScreenshots).format('0,0')} screenshots (${numeral(props.localNbOfScreenshots).format('0,0')} saved on this device)`}
    </p>
  </div>
);

ExplorerControls.propTypes = {
  selectedScreenshotKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCompareScreenshots: PropTypes.func.isRequired,
  handleRemoveLocalScreenshots: PropTypes.func.isRequired,
  removeAllLocalScreenshotsButton: PropTypes.func.isRequired,
  totalNbOfScreenshots: PropTypes.number,
  localNbOfScreenshots: PropTypes.number,
  toggleGridView: PropTypes.bool.isRequired,
  handleUpdateControl: PropTypes.func.isRequired,
  copyLinkToClipboard: PropTypes.func.isRequired,
};

ExplorerControls.defaultProps = {
  totalNbOfScreenshots: 0,
  localNbOfScreenshots: 0,
};

export default ExplorerControls;
