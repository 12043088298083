// API Gateway
const stage = process.env.REACT_APP_STAGE || 'dev';
const API_URLs = {
  production: 'https://hx4xm07rl0.execute-api.eu-west-1.amazonaws.com/production',
  dev: 'https://uwoiq8edy1.execute-api.eu-west-1.amazonaws.com/dev',
};

const APIbaseURL = API_URLs[stage];

// eslint-disable-next-line import/prefer-default-export
export { APIbaseURL };
