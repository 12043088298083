import axios from 'axios';
import { APIbaseURL, weatherAPIbaseURL } from '../utils/awsConfig';

const axiosJson = axios.create({ baseURL: APIbaseURL, responseType: 'json' });
const axiosText = axios.create({ baseURL: APIbaseURL, responseType: 'text' });

const toQueryString = (params) => Object.keys(params)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  .join('&');

const getAxiosErrorMessage = (err) => {
  if (err.response) {
    const { status, statusText } = err.response;
    return `${status} - ${statusText}`;
  } if (err.request) {
    const { status, statusText } = err.request;
    return `${status} - ${statusText}`;
  }
  return err.message;
};

const downloadData = async (token, options, onDownloadProgress) => {
  const key = options.type === 'full' ? 'Outputs/Latest/Europa - data.csv' : '';
  // const key = options.type === 'full' ? 'Outputs/Latest/EuropaSmall - data.csv' : '';
  // const key = options.type === 'full' ? 'Outputs/Latest/Dash DB - data.csv' : '';
  // const key = options.type === 'full' ? 'Outputs/Latest/reducedDB - values.csv' : '';
  // const key = options.type === 'full' ? 'Outputs/Latest/Reduced DB - SOR attendance.csv' : '';
  axiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;

  // get S3 pre-signed URL
  const rawPresignedURL = await axiosJson.get(`/getS3FileURL?${toQueryString({ filePath: key })}`);
  const preSignedURL = rawPresignedURL.data;
  // console.log(preSignedURL);

  // Download the file
  try {
    const rawData = await axiosText.get(preSignedURL, { onDownloadProgress });
    const data = rawData.data.split(/\r?\n/);
    const data_array = data.map((el) => el.split(','));

    // console.log(data_array.length);
    // console.log(data_array[0]);
    // console.log(data_array[1]);

    // convert to array of objects
    const headers = data_array[0];
    const dataValues = data_array.slice(1);
    const objectsData = dataValues.map((el) => {
      const currentRow = {};
      for (let i = 0; i < headers.length; i += 1) {
        if (['Year'].indexOf(headers[i]) >= 0) {
          currentRow[headers[i]] = el[i];
        } else {
          currentRow[headers[i]] = el[i] === '0.0' ? 0 : +el[i] || el[i]; // converting numbers
        }
      }
      return currentRow;
    });

    return { data: objectsData, err: null };
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

const downloadRunDetails = async (token) => {
  axiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;
  // get S3 pre-signed URL
  const rawPresignedURL = await axiosJson.get(`/getS3FileURL?${toQueryString({ filePath: 'Outputs/Latest/Daily DB - run details.csv' })}`);
  const preSignedURL = rawPresignedURL.data;

  // Download the file
  try {
    const rawData = await axiosText.get(preSignedURL);
    const data = rawData.data.split(/\r?\n/);
    const headers = data[0].split(',');
    const values = data[1].split(',');
    const runDetails = {};
    for (const [index, header] of headers.entries()) {
      runDetails[header] = values[index];
    }
    return { runDetails, err: null };
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

const getWeather = async (token, Park, dateRanges, options) => {
  const weatherAxiosJson = axios.create({ baseURL: weatherAPIbaseURL, responseType: 'json' });
  weatherAxiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;
  const rawRes = await weatherAxiosJson.get('/getWeather', {
    params: { Park, dateRanges, options },
  });
  const res = rawRes.data.weatherData;
  return res;
};

export default {
  downloadData,
  downloadRunDetails,
  getWeather,
};
