import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import SalesAttendanceContainer from './SalesAttendance/SalesAttendanceContainer';
import PassContainer from './Pass/PassContainer';

const EuropaContainer = (props) => (
  <div className="europa">
    <Switch>
      <Route exact path="/Europa/Sales & Attendance" component={SalesAttendanceContainer} />
      <Route exact path="/Europa/Pass" component={PassContainer} />
    </Switch>
  </div>
);

export default EuropaContainer;
