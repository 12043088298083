import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Switch } from '@blueprintjs/core';
import MyMultiSelect from 'Components/MyMultiSelect/MyMultiSelect';
import MySelect from 'Components/MySelect/MySelect';

const Options = (props) => (
  <div className="options">
    <div className="timeGrouping">
      <h5>Grouping</h5>
      <MySelect
        width={100}
        items={props.timeGroupings}
        activeItem={props.options.groupBy}
        handleItemSelect={(newValue) => props.handleUpdateOptions({ optionName: 'groupBy', newValue })}
        filterable={false}
        fill
      />
    </div>
    <div className="priorYears">
      <h5>Prior Years</h5>
      <MyMultiSelect
        items={Object.keys(props.priorYears).filter((el) => el !== 'CY')}
        selectedItems={props.globalOptions.priorYears}
        updateSelectedItems={(newValue) => props.handleUpdateOptions({ isGlobalOption: true, optionName: 'priorYears', newValue })}
      />
    </div>
    <div className="toggleTable">
      <ButtonGroup>
        <Button
          active={!props.options.toggleTable}
          onClick={() => props.handleUpdateOptions({ optionName: 'toggleTable', newValue: false })}
          // text="Charts"
          icon="timeline-line-chart"
        />
        <Button
          active={props.options.toggleTable}
          onClick={() => props.handleUpdateOptions({ optionName: 'toggleTable', newValue: true })}
          // text="Tables"
          icon="th"
        />
      </ButtonGroup>
    </div>
    <div className="toggleCumulative">
      <Switch
        checked={props.options.toggleCumulative}
        onChange={() => props.handleUpdateOptions({ optionName: 'toggleCumulative', newValue: !props.options.toggleCumulative })}
        label="Cumulative"
      />
    </div>
  </div>
);

Options.propTypes = {
  timeGroupings: PropTypes.arrayOf(PropTypes.string).isRequired,
  priorYears: PropTypes.objectOf(
    PropTypes.shape({
      color: PropTypes.shape({
        dark: PropTypes.string,
        light: PropTypes.string,
      }),
      delta: PropTypes.number,
    }),
  ).isRequired,
  globalOptions: PropTypes.shape({
    priorYears: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  options: PropTypes.shape({
    groupBy: PropTypes.string.isRequired,
    toggleTable: PropTypes.bool.isRequired,
    toggleCumulative: PropTypes.bool.isRequired,
  }).isRequired,
  handleUpdateOptions: PropTypes.func.isRequired,
};

export default Options;
