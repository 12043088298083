import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import Data from './Data';
import IoActions from '../Actions/ioActions';

const DataContainer = (props) => {
  const token = useSelector((store) => store.authReducer.token || '');
  const [dataUpdateStatus, setDataUpdateStatus] = React.useState({ upToDate: true });

  const runDetails = useQuery(['runDetails'], () => IoActions.downloadRunDetails(token));
  React.useEffect(() => {
    if (runDetails.data) {
      const lastDateRemote_year = runDetails.data.lastDate.slice(0, 4);
      const lastDateRemote_month = runDetails.data.lastDate.slice(4, 6);
      const lastDateRemote_day = runDetails.data.lastDate.slice(6, 8);
      const lastDateRemote = `${lastDateRemote_year}-${lastDateRemote_month}-${lastDateRemote_day}`;
      const lastDateLocal = props.dataInfo?.Io_trafficConversion_fullData?.lastDate;
      setDataUpdateStatus({
        lastDateRemote,
        lastDateLocal,
        upToDate: new Date(lastDateRemote) <= new Date(lastDateLocal),
      });
    }
  }, [runDetails.data, props.dataInfo]);

  return (
    <Data
      dataInfo={props.dataInfo}
      runDetails={runDetails}
      dataUpdateStatus={dataUpdateStatus}
      refreshData={props.refreshData}
      downloadProgress={props.downloadProgress}
    />
  );
};

DataContainer.propTypes = {
  // module: PropTypes.string.isRequired,
  dataInfo: PropTypes.objectOf(
    PropTypes.shape({
      nbRows: PropTypes.number,
      fields: PropTypes.arrayOf(PropTypes.string),
      lastDate: PropTypes.string,
    }),
  ).isRequired,
  refreshData: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
};

DataContainer.defaultProps = {
  downloadProgress: null,
};

export default DataContainer;
