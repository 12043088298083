/* eslint-disable function-paren-newline */
import React from 'react';

const ptIconWrapper = (path, viewboxDefaultWidth = 16, viewboxDefaultHeight = 16) => (
  <span className="bp3-icon">
    <svg
      className="pt-icon"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="16"
      height="16"
      viewBox={`0 0 ${viewboxDefaultWidth} ${viewboxDefaultHeight}`}
    >
      {path}
    </svg>
  </span>
);

const lineTypes = ptIconWrapper(
  <>
    <rect y="12" width="16" height="2" rx="1" />
    <rect y="8" width="7.5" height="2" rx="1" />
    <rect x="8.5" y="8" width="7.5" height="2" rx="1" />
    <rect y="4" width="5" height="2" rx="1" />
    <rect x="11" y="4" width="5" height="2" rx="1" />
    <rect x="5.5" y="4" width="5" height="2" rx="1" />
    <rect width="2" height="2" rx="1" />
    <rect x="2.79999" width="2" height="2" rx="1" />
    <rect x="5.59998" width="2" height="2" rx="1" />
    <rect x="8.39996" width="2" height="2" rx="1" />
    <rect x="11.2" width="2" height="2" rx="1" />
    <rect x="13.9999" width="2" height="2" rx="1" />
  </>, 16, 14);


const thinSolidLine = ptIconWrapper(<rect width="20" height="1" rx="0.5" />, 20, 1);
const mediumSolidLine = ptIconWrapper(<rect width="20" height="2" rx="1" />, 20, 2);
const thickSolidLine = ptIconWrapper(<rect width="20" height="4" rx="2" />, 20, 4);


const thinDashedLine = ptIconWrapper(
  <>
    <rect width="7.5" height="1" rx="0.5" />
    <rect x="8.5" width="7.5" height="1" rx="0.5" />
  </>, 16, 1);
const mediumDashedLine = ptIconWrapper(
  <>
    <rect width="7.5" height="2" rx="1" />
    <rect x="8.5" width="7.5" height="2" rx="1" />
  </>, 16, 2);
const thickDashedLine = ptIconWrapper(
  <>
    <rect width="7.5" height="4" rx="2" />
    <rect x="8.5" width="7.5" height="4" rx="2" />
  </>, 16, 4);


const thinSmallDashedLine = ptIconWrapper(
  <>
    <rect width="5" height="1" rx="0.5" />
    <rect x="11" width="5" height="1" rx="0.5" />
    <rect x="5.5" width="5" height="1" rx="0.5" />
  </>, 16, 1);
const mediumSmallDashedLine = ptIconWrapper(
  <>
    <rect width="5" height="2" rx="1" />
    <rect x="11" width="5" height="2" rx="1" />
    <rect x="5.5" width="5" height="2" rx="1" />
  </>, 16, 2);
const thickSmallDashedLine = ptIconWrapper(
  <>
    <rect width="5" height="4" rx="2" />
    <rect x="11" width="5" height="4" rx="2" />
    <rect x="5.5" width="5" height="4" rx="2" />
  </>, 16, 4);


const thinDashDotLine = ptIconWrapper(
  <>
    <rect width="6" height="1" rx="0.5" />
    <rect x="10" width="6" height="1" rx="0.5" />
    <rect x="7" width="2" height="1" rx="0.5" />
  </>, 16, 1);
const mediumDashDotLine = ptIconWrapper(
  <>
    <rect width="6" height="2" rx="1" />
    <rect x="10" width="6" height="2" rx="1" />
    <rect x="7" width="2" height="2" rx="1" />
  </>, 20, 2);
const thickDashDotLine = ptIconWrapper(
  <>
    <rect width="11" height="4" rx="2" />
    <rect x="12" width="4" height="4" rx="2" />
  </>, 16, 4);


const thinDotsLine = ptIconWrapper(
  <>
    <rect width="1" height="1" rx="0.5" />
    <rect x="2" width="1" height="1" rx="0.5" />
    <rect x="4" width="1" height="1" rx="0.5" />
    <rect x="6" width="1" height="1" rx="0.5" />
    <rect x="8" width="1" height="1" rx="0.5" />
    <rect x="10" width="1" height="1" rx="0.5" />
    <rect x="12" width="1" height="1" rx="0.5" />
    <rect x="14" width="1" height="1" rx="0.5" />
  </>, 16, 1);
const mediumDotsLine = ptIconWrapper(
  <>
    <rect width="2" height="2" rx="1" />
    <rect x="2.79999" width="2" height="2" rx="1" />
    <rect x="5.59998" width="2" height="2" rx="1" />
    <rect x="8.39996" width="2" height="2" rx="1" />
    <rect x="11.2" width="2" height="2" rx="1" />
    <rect x="13.9999" width="2" height="2" rx="1" />
  </>, 16, 2);
const thickDotsLine = ptIconWrapper(
  <>
    <rect width="4" height="4" rx="2" />
    <rect x="6" width="4" height="4" rx="2" />
    <rect x="12" width="4" height="4" rx="2" />
  </>, 16, 4);

export default {
  lineTypes,
  thinSolidLine,
  mediumSolidLine,
  thickSolidLine,
  thinDashedLine,
  mediumDashedLine,
  thickDashedLine,
  thinSmallDashedLine,
  mediumSmallDashedLine,
  thickSmallDashedLine,
  thinDashDotLine,
  mediumDashDotLine,
  thickDashDotLine,
  thinDotsLine,
  mediumDotsLine,
  thickDotsLine,
};
