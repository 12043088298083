import React from 'react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { lineColors } from 'AppModules/Ganymede/utils/constants';
import { Intent, Toaster } from '@blueprintjs/core';
import { add, differenceInDays, format } from 'date-fns';
import ganymedeAPI from '../API/ganymedeAPI';
import Prices from './Prices';
import './Prices.css';
import ganymedeActions from '../Actions/ganymedeActions';

const queryClient = new QueryClient();
const copyDataToClipboardToaster = Toaster.create();

const PricesContainer = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.authReducer.token || '');
  const priceMode = useSelector((store) => store.ganymedeReducer.prices.priceMode) || 'bySnapshotDate';
  const filtersSelection = useSelector((store) => store.ganymedeReducer.prices.filtersSelection);

  const pricesQuery = useQuery({
    queryKey: JSON.stringify({ priceMode, filtersSelection }),
    queryFn: () => ganymedeAPI.getPricesData(token, priceMode, filtersSelection),
  });

  if (pricesQuery.isError) {
    return <span>Error: {pricesQuery.error?.message}</span>;
  }

  const copyDataToClipboard = async (tableHeaders, tableData) => {
    const text = [tableHeaders, ...tableData].map((row) => row.join('\t')).join('\n');
    try {
      await navigator.clipboard.writeText(text);
      copyDataToClipboardToaster.show({ message: 'Data succesfully copied to clipboard', intent: Intent.SUCCESS, icon: 'tick' });
    } catch (e) {
      copyDataToClipboardToaster.show({ message: `Error copying data to clipboard: ${e.message}`, intent: Intent.DANGER, icon: 'warning-sign' });
    }
    // return res;
  };

  const pricesData =
    pricesQuery.isSuccess && pricesQuery?.data?.items.length > 0
      ? pricesQuery.data.items.map((output, index) => {
          const allDates = output.data.map((el) => new Date(priceMode === 'bySnapshotDate' ? el.eventDate : el.snapshotDate)).sort((a, b) => a - b);
          const lastDate = allDates.slice(-1)[0];
          const filledDates = allDates.slice(0, 1);
          let currentDate = allDates[0];
          while (currentDate < lastDate) {
            currentDate = add(currentDate, { days: 1 });
            filledDates.push(currentDate);
          }
          const filledDates_formatted = filledDates.map((el) => format(el, 'yyyy-MM-dd'));

          const filledPrices = filledDates_formatted.map((date) => {
            const value = output.data.find((el) => format(new Date(priceMode === 'bySnapshotDate' ? el.eventDate : el.snapshotDate), 'yyyy-MM-dd') === date);
            return value?.price;
          });

          // Shift dates if it's an addtional snapshotDate and it's roughly 1, 2, etc. years before main snapshotDate
          let deltaYears;

          if (priceMode === 'bySnapshotDate') {
            const mainSnapshotDate = pricesQuery.data.items[0].snapshotDate;
            const currentSnapshotDate = output.snapshotDate;
            const delta_currentToMainSnapshotDate = differenceInDays(new Date(mainSnapshotDate), new Date(currentSnapshotDate));
            deltaYears = Math.round(delta_currentToMainSnapshotDate / 365);
          } else {
            const mainEventDate = pricesQuery.data.items[0].eventDate;
            const currentEventDate = output.eventDate;
            const delta_currentToMainEventDate = differenceInDays(new Date(mainEventDate), new Date(currentEventDate));
            deltaYears = Math.round(delta_currentToMainEventDate / 365);
          }
          const shiftToApply_days = deltaYears > 3 ? deltaYears * 364 + 7 : deltaYears * 364;

          return {
            x: filledDates.map((el) => add(new Date(el), { days: shiftToApply_days })),
            y: filledPrices,
            name: `${output.park} - ${output.product} - ${format(
              new Date(priceMode === 'bySnapshotDate' ? output.snapshotDate : output.eventDate),
              'MM/dd/yyyy',
            )}`,
            color: lineColors[index],
          };
        })
      : [];

  // console.log(pricesData);

  const udpatePriceMode = (newPriceMode) => {
    dispatch(ganymedeActions.updatePriceMode(newPriceMode));
  };

  return (
    <QueryClientProvider client={queryClient}>
      {pricesQuery.isError ? (
        'Error'
      ) : (
        <Prices
          priceMode={priceMode}
          udpatePriceMode={udpatePriceMode}
          pricesData={pricesData}
          isUpdating={pricesQuery.isLoading}
          copyDataToClipboard={copyDataToClipboard}
        />
      )}
    </QueryClientProvider>
  );
};

export default PricesContainer;
