import React from 'react';
import PropTypes from 'prop-types';
// import MyCircularProgressBar from 'Components/MyCircularProgressBar/MyCircularProgressBar';
import '../Europa.css';
import ChartsContainer from '../Charts/ChartsContainer';
import FiltersContainer from '../Filters/FiltersContainer';
import OptionsContainer from '../Options/OptionsContainer';
import DataContainer from '../Data/DataContainer';

const SalesAttendance = (props) => (
  <div className="Europa">
    <div className="topRow">
      <OptionsContainer module="salesAttendance" dataInfo={props.dataInfo} />
      <DataContainer module="salesAttendance" dataInfo={props.dataInfo} refreshData={props.refreshData} downloadProgress={props.downloadProgress} />
    </div>
    <FiltersContainer module="salesAttendance" dataInfo={props.dataInfo} filterValues={props.filterValues} />
    <ChartsContainer module="salesAttendance" chartData={props.chartData} weatherData={props.weatherData} />
  </div>
);

SalesAttendance.propTypes = {
  dataInfo: PropTypes.objectOf(
    PropTypes.shape({
      nbRows: PropTypes.number,
      fields: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  filterValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      year: PropTypes.string,
    }),
  ).isRequired,
  weatherData: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      year: PropTypes.string,
    }),
  ).isRequired,
  refreshData: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
};

SalesAttendance.defaultProps = {
  downloadProgress: null,
};

export default SalesAttendance;
