/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import MyMultiSelect from 'Components/MyMultiSelect/MyMultiSelect';
import './PricesFilters.css';
import SnapshotDateFilters from './DateFilters/SnapshotDateFilters';
import EventDateFilters from './DateFilters/EventDateFilters';

const PricesFilters = (props) => (
  <div className="PricesFilters">
    {props.priceMode === 'bySnapshotDate' ? (
      <SnapshotDateFilters
        mainDate={props.filtersSelection}
        filtersSelection={props.filtersSelection}
        handleUpdateFilter={props.handleUpdateFilter}
        handleAddDateFilter={props.handleAddDateFilter}
        handleChangeDateFilterType={props.handleChangeDateFilterType}
        handleRemoveDateFilter={props.handleRemoveDateFilter}
      />
    ) : (
      <EventDateFilters
        mainDate={props.filtersSelection}
        filtersSelection={props.filtersSelection}
        handleUpdateFilter={props.handleUpdateFilter}
        handleAddDateFilter={props.handleAddDateFilter}
        handleChangeDateFilterType={props.handleChangeDateFilterType}
        handleRemoveDateFilter={props.handleRemoveDateFilter}
      />
    )}
    <section key="Park Group">
      <h4>Park Group</h4>
      <MyMultiSelect
        items={props.filters['Park Group']?.values}
        selectedItems={props.filtersSelection['Park Group']}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Park Group', newItems)}
        ctrlSelectMode
      />
    </section>
    <section key="Park">
      <h4>Park</h4>
      <MyMultiSelect
        items={props.filters.Park?.values}
        selectedItems={props.filtersSelection.Park}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Park', newItems)}
        ctrlSelectMode
      />
    </section>
    <section key="Product">
      <h4>Product</h4>
      <MyMultiSelect
        items={props.relevantProducts}
        selectedItems={props.filtersSelection.Product}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Product', newItems)}
        ctrlSelectMode
      />
    </section>
  </div>
);

PricesFilters.propTypes = {
  priceMode: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  relevantProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  filtersSelection: PropTypes.shape({
    snapshotDate: PropTypes.object.isRequired,
    additionalSnapshotDates: PropTypes.arrayOf(PropTypes.object).isRequired,
    'Park Group': PropTypes.arrayOf(PropTypes.string).isRequired,
    Park: PropTypes.arrayOf(PropTypes.string).isRequired,
    Product: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
  handleAddDateFilter: PropTypes.func.isRequired,
  handleChangeDateFilterType: PropTypes.func.isRequired,
  handleRemoveDateFilter: PropTypes.func.isRequired,
};

export default PricesFilters;
