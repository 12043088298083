import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { add, sub } from 'date-fns';
import { Button } from '@blueprintjs/core';

const PricesChart = (props) => (
  <div className="PricesChart">
    <Plot
      name={props.title}
      data={[
        ...props.data.reverse().map((data) => ({
          // Reverse so that the CY curve appears on top on the chart
          x: data.x,
          y: data.y,
          name: data.name,
          mode: 'lines',
          type: 'scatter',
          marker: {
            size: 6,
            color: props.isDarkMode ? data.color.dark : data.color.dark,
            line: {
              width: 1,
              color: props.isDarkMode ? '#394B59' : '#f5f8fa',
            },
          },
          line: {
            color: props.isDarkMode ? data.color.dark : data.color.dark,
            width: 1,
            shape: 'hvh',
          },
          // hoverinfo: 'text',
          text: data.text,
          textfont: { size: 2, color: 'red' },
        })),
        {
          x: props.data[0].x,
          y: props.data[0].x.map((el) =>
            el.getDay() === 0 || el.getDay() === 6
              ? Math.max(
                  ...props.data
                    .map((el2) => el2.y)
                    .flat()
                    .filter((el3) => !!el3),
                )
              : 0,
          ),
          name: 'weekends',
          type: 'bar',
          showlegend: false,
          marker: {
            color: props.isDarkMode ? '#71808E' : '#BFCCD6',
            opacity: 0.2,
          },
        },
      ]}
      layout={{
        width: props.width,
        height: props.height,
        // title: {
        //   text: props.title,
        //   font: {
        //     color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
        //     size: 16,
        //   },
        // },
        showlegend: true,
        legend: {
          font: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
        },
        margin: {
          t: 30,
          b: 30,
          l: 30,
          r: 30,
        },
        xaxis: {
          showgrid: false,
          tickfont: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
          // rangeselector: {
          //   buttons: [
          //     {
          //       count: 1,
          //       label: '1m',
          //       step: 'month',
          //       stepmode: 'backward',
          //     },
          //     {
          //       count: 6,
          //       label: '6m',
          //       step: 'month',
          //       stepmode: 'backward',
          //     },
          //     { step: 'all' },
          //   ],
          // },
          rangeslider: {},
          range:
            props.priceMode === 'byEventDate'
              ? [
                  sub(
                    Math.max(
                      ...props.data
                        .map((el2) => el2.x)
                        .flat()
                        .filter((el3) => !!el3),
                    ),
                    { months: 6 },
                  ),
                  new Date(
                    Math.max(
                      ...props.data
                        .map((el2) => el2.x)
                        .flat()
                        .filter((el3) => !!el3),
                    ),
                  ),
                ]
              : [
                  new Date(
                    Math.min(
                      ...props.data
                        .map((el2) => el2.x)
                        .flat()
                        .filter((el3) => !!el3),
                    ),
                  ),
                  add(
                    Math.min(
                      ...props.data
                        .map((el2) => el2.x)
                        .flat()
                        .filter((el3) => !!el3),
                    ),
                    { months: 6 },
                  ),
                ],
          type: 'date',
        },
        yaxis: {
          showgrid: true,
          tickfont: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
          rangemode: 'tozero',
          zeroline: true,
          zerolinecolor: props.isDarkMode ? '#778793' : '#5C7080',
          automargin: true,
          gridcolor: props.isDarkMode ? '#4A5D6C' : '#ced9e0',
        },
        paper_bgcolor: props.isDarkMode ? '#394B59' : '#f5f8fa',
        plot_bgcolor: props.isDarkMode ? '#394B59' : '#f5f8fa',
        bargap: 0,
      }}
      config={
        {
          // displayModeBar: false,
          // responsive: true,
        }
      }
    />
    <div className="chartActions">
      <Button className="copyToClipboard" icon="clipboard" minimal onClick={props.copyDataToClipboard} />
    </div>
  </div>
);

PricesChart.propTypes = {
  title: PropTypes.string.isRequired,
  priceMode: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
      y: PropTypes.arrayOf(PropTypes.number).isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.shape({
        dark: PropTypes.string.isRequired,
        light: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  copyDataToClipboard: PropTypes.func.isRequired,
};

export default PricesChart;
