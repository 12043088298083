import React from 'react';
import PropTypes from 'prop-types';
import { Intent, Toaster } from '@blueprintjs/core';
import './Charts.css';
import { useSelector } from 'react-redux';
import LinesChartContainer from './LinesChart/LinesChartContainer';
import HeatmapChartContainer from './HeatmapChart/HeatmapChartContainer';

const copyDataToClipboardToaster = Toaster.create();

// const metrics = [['Sales units', 'Sales per cap', 'Sales Revenue'], ['Attendance', 'Admission per cap', 'Admission Revenue']];
const metrics = [
  ['Sales units', 'Sales per cap', 'Sales Revenue'],
  ['Attendance', 'Weather Code', 'Real Weather - Max Temperature', 'Admission per cap', 'Admission Revenue'],
];

const ChartsContainer = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedMetricGroups, setSelectedMetricGroups] = React.useState(metrics);
  const { options } = useSelector((store) => store.europaReducer[props.module]);

  const copyDataToClipboard = async (tableHeaders, tableData) => {
    const text = [tableHeaders, ...tableData].map((row) => row.join('\t')).join('\n');
    try {
      await navigator.clipboard.writeText(text);
      copyDataToClipboardToaster.show({ message: 'Data succesfully copied to clipboard', intent: Intent.SUCCESS, icon: 'tick' });
    } catch (e) {
      copyDataToClipboardToaster.show({ message: `Error copying data to clipboard: ${e.message}`, intent: Intent.DANGER, icon: 'warning-sign' });
    }
    // return res;
  };

  return (
    props.chartData[0]?.data.length > 0 && (
      <div className="chartsContainer">
        <div className="left-column">
          <LinesChartContainer
            module={props.module}
            title="Sales units"
            metrics={{
              'Sales units': 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          <LinesChartContainer
            module={props.module}
            title="Sales per cap"
            metrics={{
              'Sales per cap': 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          <LinesChartContainer
            module={props.module}
            title="Sales Revenue"
            metrics={{
              'Sales Revenue': 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
        </div>

        <div className="right-column">
          <LinesChartContainer
            module={props.module}
            title="Attendance"
            metrics={{
              Attendance: 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          <HeatmapChartContainer
            module={props.module}
            title="Weather Code"
            metric="Weather Code"
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
          />
          <LinesChartContainer
            module={props.module}
            title="Admission per cap"
            metrics={{
              'Admission per cap': 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          <LinesChartContainer
            module={props.module}
            title="Admission Revenue"
            metrics={{
              'Admission Revenue': 'scatter',
            }}
            data={props.chartData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          <LinesChartContainer
            module={props.module}
            title={`Real Weather - Max Temperature (°${options.tempUnit})`}
            metrics={{
              'Real Weather - Max Temperature': 'scatter',
            }}
            data={props.weatherData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
            type="temperature"
          />
          <LinesChartContainer
            module={props.module}
            title={`Real Weather - Max Feels Like Temperature (°${options.tempUnit})`}
            metrics={{
              'Real Weather - Max Feels Like Temperature': 'scatter',
            }}
            data={props.weatherData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
            type="temperature"
          />
          <LinesChartContainer
            module={props.module}
            title="Real Weather - Precipitation (mm)"
            metrics={{
              'Real Weather - Precipitation': 'scatter',
            }}
            data={props.weatherData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          <LinesChartContainer
            module={props.module}
            title="Real Weather - Cloud Cover (%)"
            metrics={{
              'Real Weather - Cloud Cover': 'scatter',
            }}
            data={props.weatherData}
            copyDataToClipboard={copyDataToClipboard}
            height={350}
          />
          {/* <LinesChartContainer
          module={props.module}
          title="Weather"
          metrics={{
            'Real Weather - Max Temperature': 'scatter',
            'Real Weather - Precipitation': 'bar',
          }}
          data={props.weatherData}
          copyDataToClipboard={copyDataToClipboard}
          height={350}
        /> */}
        </div>
        {/* {selectedMetricGroups.map((metricGroup) => (
        <ChartGroupContainer
          key={metricGroup.join('_')}
          metrics={metricGroup}
          chartData={props.chartData}
          weatherData={props.weatherData}
        />
      ))} */}
      </div>
    )
  );
};

ChartsContainer.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      year: PropTypes.string,
    }),
  ).isRequired,
  weatherData: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      year: PropTypes.string,
    }),
  ).isRequired,
  module: PropTypes.string.isRequired,
};

export default ChartsContainer;
