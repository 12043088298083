// API Gateway
const stage = process.env.REACT_APP_STAGE || 'dev';
const API_URLs = {
  production: 'https://kdmo8kdwk4.execute-api.eu-west-1.amazonaws.com/dev',
  dev: 'https://kdmo8kdwk4.execute-api.eu-west-1.amazonaws.com/dev',
};

const APIbaseURL = API_URLs[stage];

// eslint-disable-next-line import/prefer-default-export
export { APIbaseURL };
