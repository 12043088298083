/* eslint-disable no-param-reassign */
import produce from 'immer';
import { buildNumberReset } from 'utils/appDefinitions';
import europaActionTypes from './Actions/europaActionTypes';
import { dimSortings, timeGroupings, yearsToDownload } from './utils/constants';

const localState = localStorage.getItem('europaState');
const loadedState = localState && JSON.parse(localState);
const firstState = {
  salesAttendance: {
    options: {
      timeGrouping: timeGroupings[1],
      priorYears: [],
      toggleTable: false,
      toggleCumulative: false,
      weatherDataType: 'relevantHoursWeatherData',
      tempUnit: 'C',
    },
    filtersSelection: {
      Park: [dimSortings.Park[0]],
      Year: ['2024'],
    },
    dataParameters: {
      filesToDownload: yearsToDownload.map((year) => ({ fileName: year, value: true })),
    },
  },
  pass: {
    options: {
      timeGrouping: timeGroupings[1],
      priorYears: ['PY'],
      toggleTable: false,
      toggleCumulative: false,
    },
    filtersSelection: {
      Park: [dimSortings.Park[0]],
      Year: ['2024'],
    },
  },
  buildNumber: process.env.REACT_APP_BUILD_NUMBER || 'local',
};

// "Repair" state loaded from local storage in case a property is missing
if (loadedState) {
  for (const categoryName of Object.keys(firstState)) {
    for (const optionName of Object.keys(firstState[categoryName])) {
      if (!loadedState[categoryName]) {
        loadedState[categoryName] = firstState[categoryName];
      } else if (!loadedState[categoryName][optionName]) {
        loadedState[categoryName][optionName] = firstState[categoryName][optionName];
      }
    }
  }
}

// use firstState if build number is below the threshold value
const initialState = loadedState && (loadedState?.buildNumber === 'local' || loadedState?.buildNumber >= buildNumberReset) ? loadedState : firstState;

// eslint-disable-next-line consistent-return
const europaReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case europaActionTypes.UPDATE_OPTION:
      draft[action.module].options[action.optionName] = action.newValue;
      break;
    case europaActionTypes.UPDATE_FILTER:
      draft[action.module].filtersSelection[action.filterName] = action.newItems;
      break;
    case europaActionTypes.TOGGLE_FILE_TO_DOWNLOAD: {
      const oldValue = draft[action.module].dataParameters.filesToDownload.find((el) => el.fileName === action.fileName).value;
      draft[action.module].dataParameters.filesToDownload.find((el) => el.fileName === action.fileName).value = !oldValue;
      break;
    }
    default:
      return draft;
  }
  localStorage.setItem('europaState', JSON.stringify(draft));
});

export default europaReducer;
