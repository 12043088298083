const dateRangeTypes = [
  {
    name: 'PY',
    timeDiff: { days: 364 },
  },
  {
    name: '2PY',
    timeDiff: { days: 364 * 2 },
  },
  {
    name: '3PY',
    timeDiff: { days: 364 * 3 },
  },
  {
    name: '4PY*',
    timeDiff: { days: 364 * 4 + 7 },
  },
  {
    name: '5PY*',
    timeDiff: { days: 364 * 5 + 7 },
  },
  {
    name: 'Custom',
    timeDiff: { days: 0 },
  },
];

const otherObject = {};

export default {
  dateRangeTypes,
  otherObject,
};
